@import "../../styles/mixin";
@import "../../styles/variable";

.active-box {
  background-color: #1db195;
}

.debit-note {
  display: flex;
  flex-direction: row-reverse;
}
.product-selected {
  display: flex;
  gap: 12px;
  align-items: center;
  .product-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .selected-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.15px;

    color: #818181;
  }
}
.batch-box-parent {
  display: flex;
  gap: 16px;
  margin-bottom: 14px;
  .batch-box {
    cursor: pointer;
    width: 180px;
    height: auto;
    background: #f9f9f9;
    padding: 12px;
    gap: 12px;
    font-size: 11px;
    font-family: "Poppins";
    line-height: 16px;
    color: #406578;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    .check-box {
      border: 0.8px solid darkgray;
      width: 20px;
      height: 18px;
      border-radius: 50%;
      position: relative;
      bottom: 34px;
    }
    .value {
      color: #333333;
    }
  }
}

.product-bill {
  .table-body {
    table {
      width: 100%;
    }
    td {
      border: 1px solid #f0f0f0;
      border-collapse: collapse;
      padding: 0px 10px;
    }
    .head-center {
      padding: 4px 10px !important;
    }

    .padd-0 {
      padding: 0;
    }
  }
  .table-footer {
    width: 100%;
    display: flex;
    .note-wrapper {
      // margin-right: 20px;
      width: 60%;
    }
    .text-area-section {
      width: 50%;
    }
  }
  .width-3 {
    width: 3%;
  }
  .amt-width {
    width: 12%;
  }
  .sub-col {
    width: 50%;
    padding: 4px 0;
    text-align: center;
    .input-field {
      width: 95%;
    }
  }
  .right-border {
    border-right: 1px solid #f0f0f0;
  }
  .qty-th {
    width: 25%;
  }
  .text-center {
    text-align: center;
    justify-content: center;
  }

  .bank-detail-wrapper {
    border: 1px solid $light-border;
    border-radius: 4px;
    margin: 12px 0;
    display: flex;
    .bank-detail {
      padding: 12px 16px;
      width: 60%;
      border: 1px solid $light-border;
      .cash-title {
        margin-bottom: 12px;
      }
    }
    .credit-detail {
      border-bottom: 0;
      width: 40%;
    }
  }
  .btn-section {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    .main-button {
      width: 180px;
    }
  }
  .total-card-wrapper {
    width: 100%;
    margin-left: auto;
    border: 1px solid #f0f0f0;
    padding: 20px 0;
    margin-top: 10px;
    border-radius: 5px;
    .line-segment {
      margin-bottom: 10px;
    }
  }
  .total-card {
    width: 80%;
    margin-left: auto;
    margin-bottom: 10px;
    margin-right: 22px;
    th {
      text-align: center;
    }
    td {
      border: none;
      padding-left: 10px;
    }
  }
  .table-body {
    border: 1px solid $light-border;
    border-radius: 4px;
    margin-bottom: 15px;

    .table-header {
      background-color: $accent-2-charcoal-pastel;
      border-bottom: 1px solid $light-border;
      .table-content {
        padding: 18px 16px;
        color: $small-content;
        text-transform: uppercase;
      }
      .table-content {
        margin: 0;
        padding: 4px 0;
        text-align: center;
        font-weight: 500;
        font-size: 10px;
        color: #666666;
        border-right: 1px solid #f0f0f0;
      }
      .equal-width {
        padding: 0;
        .small-content {
          margin: 0;
          padding: 4px 0;
          font-size: 10px;
        }

        .main-field {
          border-bottom: 1px solid $light-border;
        }
        .sec-field {
          border-left: 1px solid $light-border;
        }
        .pri-field,
        .sec-field {
          width: 50%;
        }
      }
    }
    .side-border {
      border-right: 1px solid $light-border;
    }
    .product-width {
      width: 28%;
    }
    .amount-width {
      width: 8%;
    }
    .no-space {
      .input-field {
        text-align: end;
      }
    }
    .symbol-wrapper {
      padding: 0 16px !important;
      .symbol-number {
        width: 30px;
      }
      .side-border {
        height: 55px;
        margin-right: 13px;
      }
    }
    .sub-title {
      .symbol-wrapper {
        .symbol-number {
          width: 35px;
        }
      }
      .reverse-amt {
        .reverseFunction {
          .input-label {
            margin: 0;
          }
        }
      }
    }
  }
  .notes-section {
    align-items: flex-start;
    .note-wrapper {
      margin-right: 20px;
      width: 400px;
      .bill-section {
        display: unset;
      }
      .bill-section {
        margin: 16px 0;
      }
      .text-area-section {
        width: 30%;
        margin-right: 10px;
        align-items: unset;
        .text-area-field {
          resize: none;
          width: 100%;
        }
      }
    }
    .card-wrapper {
      width: 60%;

      .total-card {
        border: 1px solid $light-border;
        border-radius: 4px;
        width: 100%;
        .total-table {
          width: 48%;
          margin-top: 10px;
          margin-bottom: 6px;
          white-space: nowrap;
          margin-left: 20px;
          tbody {
            td {
              padding: 0px 5px !important;
            }
          }
        }
      }
    }
  }
  .line-segment {
    padding: 0 24px;
    .input-field {
      text-align: right;
      &:disabled {
        color: $semantics-black;
      }
    }
    .input-space {
      margin: 8px 0;
    }
    .content {
      font-weight: 400;
    }
  }
  .total-bottom {
    background-color: $accent-2-charcoal-pastel;
    border-top: 1px solid $light-border;

    .content,
    .input-field {
      font-weight: 600;
      color: $semantics-black;
    }
  }
  .head-section {
    align-items: center;
    border-top: 1px solid #f0f0f0;
    .content {
      margin-bottom: 0;
      color: #000;
    }
  }
  .bank-details {
    padding: 16px;
    .nepali-date-picker {
      table {
        tr {
          td {
            padding: 6px !important;
          }
        }
      }
    }
    table {
      width: 100%;
      margin-top: 12px;
      th {
        padding: 16px;
      }
      td {
        padding: 12px 16px;
        border-bottom: 0px !important;
      }
      .border-btm-0 {
        border-bottom-width: 0;
      }
      .border-top-0 {
        border-top-width: 0;
      }
      .credit-width {
        width: 40%;
      }
      .border-right-width {
        border-right-width: 1px;
      }
    }
  }
  .vertical-align-0 {
    vertical-align: unset;
    textarea {
      resize: none;
    }
  }
}
