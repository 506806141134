$semantics-white: #ffffff;
$semantics-black: #000000;
$line-color: #cccccc;
$border-radius: 5px;
$card-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
$contentColor: #818181;
$accent-2-charcoal-50: #999999;

$pri-orange-dark: #c27e3a;
$pri-orange-100: #f29d49;
$pri-orange-25: #fce7d1;
$pri-orange-75: #f5b576;
$pri-orange-10: #fef5ed;
$pri-orange-5: #fefaf6;
$pri-orange-pastel: #fefcfa;
$sec-mint-dark: #178e77;
$sec-mint-100: #1db195;
$sec-mint-50: #8ed8ca;
$sec-mint-75: #56c4af;
$sec-mint-25: #c7ebe4;
$sec-mint-10: #e8f7f4;
$sec-mint-5: #f4fbfa;
$sec-mint-pastel: #f8fdfc;
$accent-1-deep-indigo-dark: #00283c;
$accent-1-deep-indigo-100: #00324b;
$sec-mint-10: #e8f7f4;
$pri-orange-50: #f8cea4;
$accent-1-deep-indigo-75: #406578;
$accent-1-deep-indigo-50: #8098a5;
$accent-1-deep-indigo-25: #bfccd2;
$accent-1-deep-indigo-10: #e5ebed;
$accent-1-deep-indigo-5: #f2f5f6;
$accent-2-charcoal-dark: #292929;
$accent-1-deep-indigo-pastel: #f7f9fa;
$accent-2-charcoal-100: #333;
$accent-2-charcoal-75: #666;
$accent-2-charcoal-50: #999;
$accent-2-charcoal-25: #ccc;
$accent-2-charcoal-10: #ebebeb;
$accent-2-charcoal-5: #f5f5f5;
$semantics-blue-dark: #0082b3;
$accent-2-charcoal-pastel: #f9f9f9;
$semantics-blue: #00a3e0;
$semantics-blue-50: #80d1ef;
$semantics-blue-10: #e5f6fc;
$semantics-blue-25: #bfe8f7;
$semantics-blue-5: #f2fbfd;
$semantics-blue-pastel: #f8fdfe;
$semantics-green-dark: #009974;
$semantics-green: #00bf91;
$semantics-green-50: #80dfc8;
$semantics-blue-75: #40bae8;
$semantics-green-25: #bfefe4;
$semantics-green-5: #f2fcfa;
$semantics-green-pastel: #e5f9f4;
$semantics-yellow-dark: #c08f1c;
$semantics-yellow-75: #f4c65a;
$semantics-yellow-50: #f7d991;
$semantics-yellow-25: #fbecc8;
$semantics-yellow-5: #fefbf4;
$semantics-yellow: #f0b323;
$semantics-yellow-pastel: #fdf7e9;
$semantics-red: #da291c;
$semantics-red-dark: #ae2116;
$semantics-red-75: #e35f55;
$semantics-red-50: #ec948d;
$semantics-red-25: #f6c9c6;
$semantics-green-75: #40cfad;
$semantics-red-5: #fdf4f4;
$semantics-red-pastel: #fbeae8;
$semantics-black: #000;
$semantics-white: #fff;
$role-color-cm-dark: #788be5;
$role-color-cm: #859aff;
$role-color-cm-10: #f3f5ff;
$role-color-cm-5: #fcf9fc;
$role-color-e-dark: #d96374;
$role-color-e: #f16e81;
$role-color-e-25: #fbdbdf;
$role-color-e-10: #fef0f2;
$role-color-ce-dark: #ab7bb3;
$role-color-e-5: #fef8f9;
$role-color-cm-75: #cea7d5;
$role-color-ce: #be89c7;
$role-color-cm-50: #dfc4e3;
$role-color-cm-25: #efe1f1;
$role-color-ce-10: #f8f3f9;
$role-color-bm: #69c6c0;
$role-color-pm: #95d277;
$charcoal-75: #606b71;
$charcoal-100: #2b3a42;
$charcoal-10: #ececec;
$charcoal-25: #caced0;
$charcoal-50: #959ca0;

$dark-text-color: #2c2948;
$extra-light-text-color: #908f9b;
$gray-5: #e0e0e0;
$small-content: #666666;
$maroon-red: #b11d39;
$light-text-color: #6f6f6f;
$deletColor: #e03a3a;
$light-border: #f0f0f0;
$silver-black: #333333;
$field-set: #caced0;
$card-bg: #f7f7f7;
$gray-0: #555555;
