.reminder-page {
  position: relative;
  min-height: 500px;
  .pos-center {
    top: calc(50%);
    left: calc(56%);
  }
    .card {
      border: none;
      .content {
        font-size: 12px;
        font-weight: 400;
        color: #000;
      }
      .card-header {
        font-size: 12px;
        font-weight: 500;
        border: none;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      }
      .card-head-section {
        padding: 10px;
        font-size: 12px;
        font-weight: 500;
      }
      .card-row {
        padding: 10px;
        border-bottom: 1px solid #f1f1f1;
        .card-title-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          .card-title {
            font-size: 12px;
            font-weight: 500;
          }
          .high-light {
            padding: 2px 10px;
            border-radius: 3px;
            background-color: #fce6e6;
            gap: 6px;
            display: flex;
          }
          .red {
            color: #cb0101;
            font-size: 12px;
          }
          .calender{
            margin-top: -5px;
          }
        }
  
        .card-body {
          display: flex;
          justify-content: space-between;
          padding: 0;
          align-items: center;
  
          .green {
            color: #56c4b0;
          }
        }
      }
  
      .card-footer {
        display: flex;
        padding: 10px;
        margin-top: 2px;
      }
    }
  }
  
