@import "../../styles/mixin";
@import "../../styles/variable";

.profile-page {
  .field-group{
    display: flex;
    align-items: flex-start;
  }
  .profile-body {
    margin: 24px 0;
    border-top: 1px solid $charcoal-25;
    padding-top: 24px;
    .desc-wrap {
      margin-left: 10px;
      .name {
        color: $semantics-black;
        margin-right: 10px;
      }
    }
  }
}
