.error-page {
  padding: 60px 40px;
  text-align: center;
  .icon-section {
    margin-bottom: 10px;

    img {
      width: 64px;
    }
  }
  .error-title {
    font-size: 16px;
  }
  .text-section {
    text-align: center;
  }
  .sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
  }
  .error {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .btn-mid {
    display: flex;
    justify-content: center;
  }
  .text-section {
    margin: auto;
    width: 35%;
  }
}
