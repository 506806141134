@import "../../styles/mixin";
@import "../../styles/variable";

.pay-slip-page {
  .user-info-field {
    align-items: flex-start;
    margin-top: 20px;
    .full-width {
      margin-right: 24px;
      width: 100%;
    }
  }
  .bill-info {
    margin: 16px 0 0 0;
    padding: 12px 16px;
    background-color: $accent-2-charcoal-5;
    border-radius: 4px;
    .small-content {
      color: $accent-1-deep-indigo-75;
      margin-right: 8px;
    }
    .content {
      color: $silver-black;
    }
  }
 
  .searchable-name {
    margin-bottom: 10px;
  }
}
