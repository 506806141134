@import "../../styles/mixin";
@import "../../styles/variable";

.customer-modal {
  padding: 0 35px 15px 35px;
  .no-mg-btm {
    margin-bottom: 0 !important;
  }
  .input-field {
    margin-bottom: 24px;
  }
  .data-field {
    margin-bottom: 24px;
    .input-field {
      margin-bottom: 0;
    }
  }
  .unactive {
    font-size: 12px;
    align-items: center;
    display: flex;
    color: #1db195;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 16px;
    transition: 0.7s;
  }
  .active-bg {
    border-radius: 4px;
    background-color: #1db195;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    color: white;
    padding: 6px 16px;
    transition: 0.7s;
  }
  .disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .tab-btn {
    border: 1px solid #1db195;
    border-radius: 4px;
    display: flex;
    width: fit-content;
    margin-bottom: 20px;
  }
  .radio-box-area {
    input {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border: 1px solid #1db195;
      border-radius: 4px;
      outline: none;
      margin-right: 12px;
      position: relative;
      padding: 8px;
    }

    input:checked {
      border: 1px solid #1db195;
      background-color: #1db195;
    }
    input:checked:after {
      color: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
