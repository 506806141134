@import "../../styles/mixin";
@import "../../styles/variable";

.active-box {
  background-color: #1db195;
}

.batch-box-parent {
  display: flex;
  gap: 16px;
  margin-bottom: 14px;
  .batch-box {
    cursor: pointer;
    width: 180px;
    height: auto;
    background: #f9f9f9;
    padding: 12px;
    gap: 12px;
    font-size: 11px;
    font-family: "Poppins";
    line-height: 16px;
    color: #406578;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 4px;
    .check-box {
      border: 0.8px solid darkgray;
      width: 20px;
      height: 18px;
      border-radius: 50%;
      position: relative;
      bottom: 34px;
    }
    .value {
      color: #333333;
    }
  }
}

.purchase-page {
  .table-body {
    border: 1px solid $light-border;
    border-radius: 4px;
    margin-bottom: 15px;
    td {
      position: relative;
    }
    // .table-header {
    //   background-color: $accent-2-charcoal-pastel;
    //   border-bottom: 1px solid $light-border;
    //   tr {
    //     display: flex;
    //     th {
    //       &:nth-child(1) {
    //         display: inline-block;
    //         width: 5%;
    //       }
    //     }
    //   }
    //   .table-content {
    //     padding: 18px 17px;
    //     color: $small-content;
    //     text-transform: uppercase;
    //   }

    //   .equal-width {
    //     padding: 0;
    //     width: 19%;
    //     .small-content {
    //       margin: 0;
    //       padding: 4px 0;
    //     }
    //     .main-field {
    //       border-bottom: 1px solid $light-border;
    //     }
    //     .sec-field {
    //       border-left: 1px solid $light-border;
    //     }
    //     .pri-field,
    //     .sec-field {
    //       width: 50%;
    //     }
    //   }
    //   .amount-width {
    //     text-align: end;
    //     width: 10%;
    //   }
    //   .product-width {
    //     width: 33%;
    //   }
    // }

    // .sub-title {
    //   display: flex;
    //   margin: 0;
    //   padding: 0;
    //   td {
    //     &:nth-child(1) {
    //       display: inline-block;
    //       padding: 18px 26px;
    //       width: 5%;
    //     }
    //   }
    //   .product-width {
    //     width: 92.6%;
    //   }
    //   .equal-width {
    //     width: 51%;
    //   }
    //   .amount-width {
    //     width: 25%;
    //   }
    // }

    .table-header {
      background-color: $accent-2-charcoal-pastel;
      border-bottom: 1px solid $light-border;
      .table-content {
        padding: 18px 16px;
        color: $small-content;
        text-transform: uppercase;
      }
      .table-content {
        margin: 0;
        padding: 4px 0;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        color: #666666;
        border-right: 1px solid #f0f0f0;
      }
      .equal-width {
        padding: 0;
        .small-content {
          margin: 0;
          padding: 4px 0;
        }

        .main-field {
          border-bottom: 1px solid $light-border;
        }
        .sec-field {
          border-left: 1px solid $light-border;
        }
        .pri-field,
        .sec-field {
          width: 50%;
        }
      }
    }
    .remove-block {
      .remove-btn {
        bottom: -5px;
      }
    }
    .quntity_th {
      width: 12%;
    }
    .amount_th {
      width: 12%;
    }
    .discount_th {
      width: 10%;
    }

    .side-border {
      border-right: 1px solid $light-border;
    }
    .product-width {
      width: 25%;
    }
    .amount-width {
      width: 8%;
    }
    .no-space {
      .input-field {
        text-align: end;
      }
    }
    .symbol-wrapper {
      padding: 0 16px !important;
      .symbol-number {
        width: 30px;
      }
      .side-border {
        height: 55px;
        margin-right: 13px;
      }
    }
    .sub-title {
      .symbol-wrapper {
        .symbol-number {
          width: 35px;
        }
      }
      .reverse-amt {
        .reverseFunction {
          .input-label {
            margin: 0;
          }
        }
      }
    }
  }
  .mt-4 {
    margin-left: 20px;
  }
  .notes-section {
    align-items: flex-start;
    .note-wrapper {
      margin-right: 20px;
      width: 400px;
      .bill-section {
        display: unset;
      }
      .bill-section {
        margin: 16px 0;
      }
      .text-area-section {
        align-items: unset;
        margin-bottom: 10px;
        .text-area-field {
          resize: none;
          width: 100%;
        }
      }
    }
    .card-wrapper {
      width: 50%;

      .total-card {
        border: 1px solid $light-border;
        border-radius: 4px;
        width: 100%;
        .total-table {
          width: 90%;
          margin-top: 10px;
          margin-bottom: 6px;
          white-space: nowrap;
          margin-left: auto;
          margin-right: 20px;
          td {
            padding: 4px;
            font-size: 12px;
          }
          th {
            padding: 4px;
            font-size: 12px;
          }
        }

        .line-segment {
          padding: 0 24px;
          .input-field {
            text-align: right;
            &:disabled {
              color: $semantics-black;
            }
          }
          .input-space {
            margin: 8px 0;
          }
          .content {
            font-weight: 400;
            font-size: 12px;
          }
        }
        .total-bottom {
          background-color: $accent-2-charcoal-pastel;
          border-top: 1px solid $light-border;

          .content,
          .input-field {
            font-weight: 600;
            font-size: 12px;
            color: $semantics-black;
          }
        }
      }
    }
  }
  .remove-block {
    .remove-btn {
      bottom: -5px;
    }
  }
}
