.confirm-pw-page {
  .top-section {
    display: flex;
    justify-content: center;
  }
  .big-ellipse {
    position: relative;
    padding: 20px;
    background-color: #1db195;
    border-radius: 50%;
    color: #fff;
  }
  .small-title {
    text-align: center;
    margin: 15px 0 20px;
  }
  .btn-section {
    margin-top: 15px;
    display: flex;
    justify-content: end;
  }
  .fix-field {
    position: relative;
  }
  .eye-section {
    position: absolute;
    right: 15px;
    top: 35px;
  }
}
