.cash-flow {
  input[type="checkbox"] {
    display: none;
  }
  p {
    margin-bottom: 0;
  }
  .table-page {
    margin: auto;
  }
  .content-black {
    color: black;
    font-size: 12px;
    font-weight: 500;
  }
  .dontShow {
    display: none;
  }
  .show {
    .padd-box {
      display: flex;
    }
  }
  .extra-padd {
    padding: 0 12px !important;
  }
  .width-30 {
    width: 30%;
  }
 
  .width-70 {
    width: 70%;
  }
  .footer-content {
    color: #1db195;
    font-size: 12px;
    font-weight: 600;
  }
  .table-wrapper {
    table {
      border: 1px solid #dcdcdc;
      height: 100%;

      .table-head-color {
        background-color: #f6f6f6;
      }
      .table-header {
        background-color: #f9f9f9;
      }
      th {
        padding: 8px 16px;
      }
      tbody {
        td {
          border-top: 0;
          border-bottom: 0;
          padding: 8px 16px;
        }
        tr {
          border-top: 0;
          border-bottom: 0;
        }
      }
      tfoot {
        border-top: 1px solid #dcdcdc;
      }
      td {
        border-bottom-width: 0;
      }
    }
  }
  .top-head {
    text-align: center;
    margin: 15px 0;
  }
  .mg-auto {
    margin: auto;
  }
  .bold-content {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
  .mgTop-auto {
    margin-top: auto;
  }
  .small-text {
    font-size: 16px;
  }
  .black-text {
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
}
