@import "../../styles/variable";

.report-page {
  .row {
    width: 100%;
  }
  table {
    th {
      font-weight: 400;
      color: #7a7a7a;;
    }
   
    .title-div {
      display: flex;
      gap: 10px;
      padding-left: 6px;
    }
  }
  .midd-section {
    .search-section {
      width: 100%;
      .search-wrapper {
        width: 300px;
        float: right;
      }
      .search-icon {
        left: 5px;
      }
      .ellipsis {
        padding-left: 30px;
      }
    }
    .star-icon {
      margin-right: 10px;
      display: flex;
      color: $contentColor;
      align-items: center;
    }
    .list {
      border-bottom: 1px dashed $contentColor;
      padding: 20px 7px;
      display: flex;
      align-items: center;
      .line-primary {
        font-size: 14px !important;
      }
    }
    .report-section{
      margin-bottom: 40px;
    }
  }
}
