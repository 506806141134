.error-page {
    padding: 60px 40px;
    text-align: center;
    .icon-section {
      margin-bottom: 10px;
  
      img {
        width: 64px;
      }
    }
    .error-title {
      font-size: 16px;
    }
    .text-section {
      text-align: center;
    }
    .sub-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      justify-content: center;

    img {
      width: 64px;
    }
  }
  .error-title {
    font-size: 18px;
  }
  .text-section {
    text-align: center;
  }
  .sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    justify-content: center;
  }
  .error {
    font-size: 14px;
    font-weight: 00;
    margin-bottom: 24px;
  }
  .btn-mid {
    display: flex;
    justify-content: center;
  }
  .text-section {
    margin: auto;
    width: 32%;
    text-align: center;
  }
}
.padd-0 {
  padding: 0px 20px !important;
}
