@import "../../styles/mixin";
@import "../../styles/variable";

* {
  margin: 0;
  padding: 0;
}
.export-title {
  font-size: 12px;
}
.stock-wrapper {
  width: 100%;
}
.search-select {
  // display: flex;
  align-items: end;
  .search-fields {
    display: flex;
    gap: 4px;
  }
  .mg-right {
    margin-right: 10px;
  }
  .download-buttons {
    margin-top: 15px;
    margin-left: 5px;
  }
  .date-wrapper {
    display: flex;
    width: 100%;
    .form-group {
      margin-right: 15px;
    }
    .input-group-append {
      position: absolute;
      right: 0;
    }
    .input-field {
      position: relative;
    }
    .input-group {
      margin-bottom: 5px;
      .btn {
        border: none;
      }
    }
  }
}
.btn-inlinement {
  align-items: center;
  display: flex;
}
.mg-btn-15{
  margin-bottom: 15px;
}
.search-wrapper {
  margin-bottom: 15px;

  .select-field {
    width: 250px;
  }
}

.float-right {
  float: right;
}
.mg-btm-16 {
  margin-bottom: 16px;
}

.downloads{
  margin-left: auto;
}

.custom-react-table {  
  .overflowTable {
    overflow-x: auto;
    width: 100%;
    min-height: 400px;
    position: relative;
    color: #000;
  }
  .show-wrapper {
    display: flex;
    align-items: center;
    // justify-content: space-around;
  }
  .pos-center {
    position: absolute;
    top: 60%;
    .spinner {
      border-width: 3px !important;
    }
  }
  .entries {
    margin-right: 20px;
    .show-drop {
      margin: 0 8px;

      .select-tag {
        background-color: $semantics-white;
        border: 1px solid $accent-2-charcoal-5;
        box-shadow: $card-shadow;
        border-radius: 4px;
        padding: 3px 12px;
        color: $accent-1-deep-indigo-100;
        font-size: 14px;
      }
    }
    .table-area {
      margin-bottom: 16px;
    }
  }
  .active {
    background-color: $sec-mint-100 !important;
    color: $semantics-white !important;
  }

  table td {
    padding: 10px !important;
  }

  .page-title h2 {
    text-align: center;
    margin-bottom: 0;
    font-size: 20px;
  }

  .page-content {
    display: flex;
    align-items: flex-start;
  }

  /* Form */
  .form-container {
    max-width: 380px;
    margin: 40px auto;
    padding: 30px 30px;
    background: gainsboro;
    border-radius: 4px;
    text-align: center;
    position: relative;
    top: 35px;
  }

  .form-row button {
    margin-top: 20px;
    padding: 10px 20px;
    transition: 0.3s all ease-in-out;
  }

  .form-row button {
    margin-top: 20px;
    padding: 10px 20px;
    transition: 0.3s all ease-in-out;
  }

  .uploadButton {
    border-radius: 5px;
    -webkit-box-shadow: 0.5px 0.5px 0.5px;
    box-shadow: 0.5px 0.5px 0.5px;
    color: rgb(255, 255, 255);
    padding: 5px 15px;
  }

  .upload-form img {
    height: 60px;
  }

  [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

  [type="file"] + label {
    background: rgb(2, 36, 226);
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    padding: 1rem 50px;
    position: relative;
    transition: all 0.3s;
    vertical-align: middle;
  }

  [type="file"] + label:hover {
    background-color: rgb(2, 36, 226);
  }

  [type="file"] + label.inputLabel {
    background-color: rgb(63, 81, 181);
    border-radius: 0;
    overflow: hidden;
  }

  [type="file"] + label.inputLabel span {
    display: inline-block;
    height: 100%;
    transition: all 0.3s;
    width: 100%;
  }

  [type="file"] + label.inputLabel::before {
    color: #fff;
    content: "\f093";
    font-family: "FontAwesome";
    font-size: 130%;
    height: 100%;
    left: 0;
    line-height: 2.6;
    position: absolute;
    top: -180%;
    transition: all 0.3s;
    width: 100%;
  }

  [type="file"] + label.inputLabel:hover {
    background-color: rgb(2, 36, 226);
  }

  [type="file"] + label.inputLabel:hover span {
    transform: translateY(300%);
  }

  [type="file"] + label.inputLabel:hover::before {
    top: -10%;
  }

  .selectedFile {
    margin: 10px 0;
  }

  /* File List */
  .file-list {
    margin-bottom: 20px;
    flex: 1;
    padding: 0 30px;
  }

  table {
    width: 100%;
    margin: 0 auto;

    .table-body-content {
      border-top: 0;
    }

    .tablehead {
      border-top: 1px solid $accent-2-charcoal-10;
    }

    td {
      padding: 10px;
    }

    tbody {
      .tablerow {
        border-bottom: 1px solid $accent-2-charcoal-10;
        cursor: pointer;

        &:nth-child(2n + 1) {
          background-color: $semantics-white;
        }

        &:nth-child(2n) {
          background-color: $semantics-white;
        }
      }
    }
  }

  table th,
  table td {
    padding: 8px;
    border: 1px solid #ddd;
    font-size: 12px;
    font-weight: 500;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $accent-2-charcoal-pastel;
    color: $small-content;
  }

  tr:nth-child(odd) {
    background-color: #dcdcdc;
  }

  tr:nth-child(even) {
    background-color: #bcc6ff;
  }

  table a {
    text-decoration: none;
    color: #333;
  }
  tfoot {
    tr:nth-last-child(1) {
      display: none;
    }
  }
  .form-group {
    position: relative;
    margin: 0 !important;
    .input-group-append {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .uploading {
    height: 100vh;
    width: 100%;
    z-index: 11;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
  }

  .fa-spinner {
    font-size: 100px;
  }

  .checkerTable th span {
    float: right;
    padding-right: 10px;
  }

  table.checkerTable {
    max-width: 1024px;
  }

  table.checkerTable th {
    min-width: 15px;
  }

  /* Pagination */
  .pagination {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 8px;
  }

  .pagination-items {
    display: flex;
    justify-content: center;
    margin: 10px;
    align-items: center;
  }

  .pagination-button {
    margin: 4px;
    padding: 8px;
    border: 1px solid #3f51b5;
    color: #3f51b5;
    border-radius: 4px;
    cursor: pointer;

    img {
      height: 20px;
    }
  }

  .pagination select {
    width: 150px;
    height: 34px;
    padding: 8px;
    color: #3f51b5;
    border-color: #3f51b5;
    font-size: 14px;
    font-weight: 600;
  }

  .pagination select:focus {
    outline: none;
  }

  .block-gap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .data-table {
      @include d-space-between;
      margin-top: 16px;
      .entry-text {
        color: $accent-2-charcoal-50;
      }

      .active-btn {
        background: $accent-1-deep-indigo-5;
        border-color: $accent-2-charcoal-10;
      }

      .data-buttons {
        @include d-flex;

        .page-number {
          margin: 0 16px;
        }

        .button-space {
          margin: 0 16px;
        }

        .prev {
          margin-right: 16px;
        }

        .next {
          margin-left: 16px;
        }

        .remove-space-rt {
          margin-right: 0;
        }

        .remove-space-lt {
          margin-left: 0;
        }
      }
    }
  }
}
.print-header {
  display: flex;
  gap: 2;
}

.small-button {
  height: 25px;
  font-size: 12px !important;
  margin: 0px 0px !important;
}

.cbms-report {
  table th {
    text-align: center;
  }
}
.top-bar-section {
  justify-content: space-between;
}
