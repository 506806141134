@import "../../styles/mixin";
@import "../../styles/variable";

.main-header {
  // padding-top: 30px;
  width: 100%;
  position: sticky;
  z-index: 12;
  top: 0px;
  left: 0;
  padding: 0 10px;
  background-color: #f8f9ff;

  .dropdown-wrapper {
    display: flex;
    position: relative;
    gap: 20px;
  }
  .dropdown-menu {
    position: relative;
  }
  .sub-dropdown-box {  
    background-color: #fff;
    padding: 20px;
    position: absolute;
    top: -42px;
    left: -306px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    transform: translate3d(0px, 36.8px, 0px);
    border: 1px solid #cecece;
    width: 300px;
    color: #7a7a7a;
    font-size: 14px;
  }
  .toggle{
    display: none;
  }
  .shortcut{
    &:hover{
      .toggle{
        display: block;
      }
    }
  }
  .sortcut-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin-bottom: 0;
    }
  }
  .key {
    padding: 4px 8px;
    border-radius: 8px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
  }
  .nav-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .logo-area {
    align-items: center;
    .icon-wrapper {
      animation: bell 1s linear infinite alternate-reverse;
      transform-origin: top;
      position: relative;
    }
    .bell-notification {
      color: #fff;
      font-size: 10px;
      font-weight: 600;
      color: blue;
      padding: 2px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 8px;
      border-radius: 50%;
      height: 14px;
      background-color: red;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @keyframes bell {
      0% {
        transform: rotate(-20deg);
      }
      100% {
        transform: rotate(20deg);
      }
    }

    .img-center {
      display: flex;
      margin-left: 20px;
      .logo {
        width: 150px;
        float: right;
      }
    }
  }

  .top-nav {
    display: flex;
    width: 20%;
    padding: 15px 10px;
    // justify-content: space-between;
  }
  .nav-icon-btn {
    display: block;
    padding: 0;
    background-color: transparent;
    .navbar-toggler-icon {
      border: 2px solid black;
      display: block;
      height: 0px;
      margin-bottom: 2px;
    }
  }

  .business-profile {
    .profile-logo {
      border-radius: 50%;
      height: 35px;
      background-color: transparent;
      .user-img-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo-section {
          border-radius: 50%;
          height: 35px;
          width: 35px;
          background-color: $sec-mint-100;
          img {
            border-radius: 50%;
            height: 35px;
            width: 35px;
            object-fit: cover;
          }
          .user-name {
            color: $semantics-white;
          }
        }
        .user-name {
          margin: 0 5px;
          color: $semantics-black;
        }
      }
    }
    .manu-name {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    .menu-btn {
      background-color: #fff;
      border-radius: 20px;
    }
    .icon {
      margin-right: 10px;
    }
    .profile-name {
      margin-left: 10px;
    }
  }
  .mg-lf {
    margin-left: 35px;
  }
}
