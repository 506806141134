@import "../../styles/mixin";
@import "../../styles/variable";

.login-page {
  .custom-card {
    padding: 0;
    width: 35%;
    margin: 50px auto;
    position: relative;
    z-index: 6;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .card-wrapper {
      padding: 0 30px 30px 30px;
      .login-title {
        padding: 35px 0;
        .content {
          margin-top: 12px;
          color: $light-text-color;
          font-weight: 400;
        }
      }
      .login-body {
        .field-block {
          margin-bottom: 20px;
        }

        .password-field {
          position: relative;
          cursor: pointer;
          color: $sec-mint-100;
          text-decoration: underline;
          .tooltiptext {
            visibility: hidden;
            width: auto;
            background-color: white;
            color: $contentColor;
            text-align: left;
            border-radius: 4px;
            padding: 4px 8px;
            position: absolute;
            z-index: 1;
            border: 1px solid $line-color;
            top: -56px;
          }
          .triangle-down {
            visibility: hidden;
            width: 0px;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 6px solid transparent;
            border-top: 10px solid $line-color;
            position: absolute;
            top: -10px;
            right: 0px;
          }
        }
        .password-field:hover {
          .tooltiptext,
          .triangle-down {
            visibility: visible;
          }
        }
      }
      .log-btn {
        width: 100%;
        .main-button {
          width: 100%;
          margin: 24px 0 35px 0;
        }
      }
      .log-note {
        color: $extra-light-text-color;
        .log-name {
          padding-left: 4px;
          color: $sec-mint-100;
          text-decoration: underline;
        }
      }
    }
  }

  .image-wrapper {
    position: relative;
    .bg-img {
      position: absolute;
      width: 100%;
      bottom: -35px;
      img {
        width: 100%;
        height: 350px;
      }
    }
  }
}
@media (max-width: 768px) {
  .login-page {
    .custom-card {
      width: 50%;
      .log-note {
        font-size: 14px;
      }
    }
    .image-wrapper {
      img {
        width: 100%;
        height: 300px;
      }
    }
  }
}
@media (max-width: 480px) {
  .login-page {
    .custom-card {
      width: 70%;
    }
    .image-wrapper {
      img {
        height: 150px;
      }
    }
  }
}
@media (max-width: 375px) {
  .login-page {
    .custom-card {
      box-shadow: none;
      width: 100%;
      margin: 0 auto;
      position: unset;
      .card-wrapper {
        padding-bottom: 0;
        .login-title {
          padding: 20px 0;
          .content {
            margin-top: 5px;
          }
        }
        .login-body {
          .field-block {
            margin-bottom: 15px;
          }
        }
        .log-btn {
          .main-button {
            margin: 15px 0;
          }
        }
      }
    }
    .image-wrapper {
      position: unset;
      .bg-img {
        position: unset;
        img {
          height: 150px;
        }
      }
    }
  }
}
@media (max-width: 320px) {
  .login-page {
    .custom-card {
      .card-wrapper {
        .login-body {
          .password-field,
          .log-note {
            font-size: 12px;
          }
        }
      }
    }
  }
}
