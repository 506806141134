@import "../../styles/variable";

.trial-balance-page {
  input[type="checkbox"] {
    display: none;
  }
  p {
    margin-bottom: 0;
  }
  .dont-show {
    display: none;
  }
  .trial-balance-table {
    p {
      margin-bottom: 0px;
    }
    .table-header {
      background-color: #f9f9f9;
      border-bottom: 1px solid #f0f0f0;
    }
    table {
      border-collapse: collapse !important;
      border: 1px solid #f0f0f0;
    }
    .title-group {
      display: flex;
      justify-content: space-between;
      .black-color {
        text-align: left;
        color: #000 !important;
      }
    }

    .sub-group {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      .black-color {
        text-align: left;
        color: #666666;
      }
    }
    .black-content {
      color: black;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 19px;
    }
    .text-left {
      text-align: left !important;
    }
    .content {
      text-align: center;
    }
    th {
      font-weight: 500;
      font-size: 12px;
      color: #666666;
      border: 1px solid #dcdcdc;
      vertical-align: middle;
      text-align: center;
    }
    td {
      border-bottom: none;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      border: 1px solid #dcdcdc;
      cursor: pointer;
    }
    .width-30 {
      width: 30%;
    }
    .width-5 {
      width: 5%;
    }
    .width-35 {
      width: 35%;
    }
    .width-15 {
      width: 15%;
    }
  }
  .top-head {
    text-align: center;
    margin: 15px 0;
  }
  .mg-auto {
    margin: auto;
  }
  .bold-content {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
  .mgTop-auto {
    margin-top: auto;
  }
  .small-text {
    font-size: 16px;
  }
  .black-text {
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
  .footer-content {
    color: $sec-mint-100 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
}

@media print {
  .footer-content {
    font-weight: bold !important;
  }
  .trading-balance-page {
    .mini-table {
      td {
        border: none;
      }
    }
  }
}
