@import "../../styles/mixin";
@import "../../styles/variable";

.select {
  background-position-x: 90%;
  @include input-style;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/images/arrowdown.png");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border-radius: 4px;
  margin-right: 2rem;
  -webkit-appearance: none;
  text-transform: capitalize;

  &:disabled {
    border: 1px solid $accent-1-deep-indigo-25;
    background: $semantics-white;
    color: $accent-2-charcoal-50;
  }

  option {
    color: $charcoal-75 !important;
    @include content-style;
    text-transform: capitalize !important;
    padding: 20px !important;
  }
}

.super {
  border: 1px solid $accent-1-deep-indigo-25;
  font-size: 16px;
  padding: 12px 16px;
  color: red;
}

.with-label {
  .select {
    margin-top: 12px;
  }
}

.filter {
  color: $accent-1-deep-indigo-100;
  font-weight: 500;
  padding: 6px 12px;
  box-shadow: $card-shadow;
  border-radius: 4px;
  border: 1px solid $accent-2-charcoal-5;
  &:focus {
    border: 1px solid $accent-2-charcoal-5;
  }
}

.label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: $accent-2-charcoal-50;
  letter-spacing: 0.25px;
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: 4px;
}

.super {
  font-size: 16px;
  color: $accent-2-charcoal-75;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 12px;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: $accent-1-deep-indigo-100;
  font-style: normal;
  letter-spacing: 0.15px;
  line-height: 34px;
  margin-bottom: 4px;
}
.title_margin {
  margin-bottom: 12px;
}

.small-content {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: $accent-2-charcoal-75;
  margin-top: 4px;
  display: block;
}
