@import "../../styles/variable";
.vat-report-page {
  p {
    margin: 0;
  }
  .width-50 {
    width: 50%;
  }
  .width-100 {
    width: 100%;
  }
  .black-color {
    color: #000 !important;
  }

  .small-content {
    color: #000;
    font-size: 12px;
    font-weight: 400;
  }
  .content {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
  }

  .width-10 {
    width: 10%;
  }
  .width-20 {
    width: 20%;
  }
  .width-30 {
    width: 30%;
  }
  .mg-top-40 {
    margin-top: 40px;
  }
  .height-100 {
    height: 100%;
  }

  .right-text {
    text-align: right;
  }

  .amt-col {
    width: 30%;
  }
  .amt-text {
    text-align: center;
  }
  .bold-content {
    font-size: 12px;
    font-weight: 500;
  }
  .main-table {
    border: 1px solid #dcdcdc;

    td {
      border: 1px solid #dcdcdc;
    }
    th {
      padding: 8px 16px;
      background-color: #1db19542;
    }

    td {
      vertical-align: top;
      padding: 0;
      td {
        padding: 8px 16px;
      }
    }
  }

  .width-60 {
    width: 60%;
  }
  .footer-table {
    width: 100%;
    border-top: none;
    border-bottom: none;
    td {
      // border: 1px solid #dcdcdc;
      vertical-align: bottom;
      border: none;
    }
  }
  .small-table {
    border-bottom: none;
    border-top: none;
    border-right: none;

    th {
      background-color: #fff;
      padding: 8px 16px;
      border-bottom: 1px solid #dcdcdc;
    }
    tbody {
      th {
        background-color: #f6f6f6;
      }
    }
    td {
      border: none;
      table {
        border-bottom: 0;
      }
    }
    tbody {
      border: none;
    }
  }
  .content-main-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
  }
  .text-right {
    text-align: right;
  }
  .padd-0 {
    padding: 0 !important;
  }
  .bill-section {
    word-break: break-all;
  }
  .vat-print {
    margin-left: 21px;
    align-items: center;
  }
}
