@import "../../styles/mixin";
@import "../../styles/variable";

.business-profile {
  .large-title {
    margin: 28px 0;
    text-align: center;
  }
  .bill-title {
    font-size: 14px;
    font-weight: 600;
  }
  .icon-mg {
    margin-right: 6px;
  }
  .add-new-button {
    display: flex;
    .mg-right-10 {
      margin-right: 10px;
    }
  }
  .profile-detail {
    .profile-wrap {
      align-items: flex-start;
      .person-img {
        margin-right: 16px;
        height: 90px;
        width: 90px;
        border-radius: 50%;
        background-color: $sec-mint-100;

        .large-title {
          color: $semantics-white;
          font-size: 30px;
        }
      }
      .text-description {
        width: 100%;
        align-items: flex-start;
        .small-title {
          font-size: 16px;
          font-weight: 500;
        }
        .user-detail {
          p {
            margin: 0;
          }
          .name,
          .location {
            text-transform: capitalize;
          }
          .email {
            padding: 8px 0;
            color: $accent-1-deep-indigo-75;
          }
          .location {
            color: $accent-1-deep-indigo-50;
          }
        }
        .add-new-button {
          .line-primary {
            padding: 0;
            .content {
              letter-spacing: 2px;
              color: $sec-mint-100;
            }
          }
        }
        span {
          img {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
.logo-image {
  margin-right: 20px;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  object-fit: cover;
}
