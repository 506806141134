.journal-detail {
  padding: 20px;
     

  td {
    border-bottom: 0;
    border-top: 0;
  }
  .border-btm-0 {
    border-bottom: 0;
    border-top: 0;
  }
  tbody{
    border-bottom: 1px solid #ced4da;;
  }
  .black-text {
    color: #000;
  }
}


.total-row td:first-child {
 
  border-bottom: none;
}
.narration-title{
  font-weight: bold !important;
  font-size: 12px !important;
}