@import "../../styles/mixin";
@import "../../styles/variable";

.opening_adjustment {
  .table-add {
    tr {
      border-bottom: 1px solid #dcdcdc;
    }
  }
  .bordered-table {
    th {
      border: 1px solid #c9c9c9;
    }
    td {
      border: 1px solid #c9c9c9;
      padding: 0;
    }
  }

  .nepali-date-picker {
    position: relative !important;
    padding: 0;
    background: unset;
    box-shadow: unset;
    z-index: 1;

    .form-control {
      font-size: 12px;
      color: #000;
      padding: 8px;
    }

    .form-control:focus {
      box-shadow: unset;
      border-color: #ced4da;
    }
  }
  .calender {
    position: absolute;
    background: #fff none repeat scroll 0 0;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: #8c8c8c;
    .calendar-wrapper {
      padding: 15px;
    }
    .month-day {
      vertical-align: middle;
    }
    .selected {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2096f5;
      border-radius: 50%;
    }
  }
  .input-group-append {
    position: relative;
    left: 20p;
    right: 34px;
  }
  .width-50 {
    width: 50%;
  }
  .text-area-section {
    padding: 0 20px;
  }
  .btn-mg {
    margin: 15px 0 20px;
  }
  .light-primary {
    height: fit-content;
  }
  .table-section {
    display: flex;
    width: 100%;
  }
  .table-add {
    .width-10 {
      width: 10%;
    }
    .width-40 {
      width: 40%;
    }
    .width-20 {
      width: 20%;
    }
  }
  .small-table {
    width: 36%;
    float: right;
    margin-top: 10px;
    margin-right: 80px;
    .header-wrap {
      padding: 8px;
      text-align: center;
    }
    .top-head {
      border: 1px solid #ced4da;
      padding: 8px;
      border-radius: 5px 5px 0 0;
      border-bottom: 0;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
    }
    .sub-part {
      width: 50%;
      padding: 8px;
      text-align: center;
    }
    .border-right {
      border-right: 1px solid #ced4da;
    }
    .mid-part {
      border-radius: 0 0 5px 5px;
      display: flex;
      border: 1px solid #ced4da;
    }
    td {
      text-align: center;
    }
  }
  .mg-btm-30 {
    margin-bottom: 30px;
  }
  .plus-icon {
    padding: 0;
  }
  table {
    width: 100%;
    margin: 0 auto;

    .del-icon {
      cursor: pointer;
    }
    .width-10 {
      width: 5%;
    }
    .width-30 {
      width: 30%;
    }
    .width-15 {
      width: 15%;
    }
    .table-body-content {
      border-top: 0;
    }

    .tablehead {
      border-top: 1px solid $accent-2-charcoal-10;
    }

    td {
      padding: 10px;
      width: 35px;
      height: 35px;
    }
  }
  .mg-btm-15 {
    margin-bottom: 15px;
  }
  .btn-section {
    width: 100%;
  }
  table th,
  table td {
    padding: 8px;
    border: 0;
    font-size: 12px;
    font-weight: 500;
    vertical-align: top;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $accent-2-charcoal-pastel;
    color: $small-content;
  }
  tbody {
    .tablerow {
      border-bottom: 1px solid $accent-2-charcoal-10;
    }
  }
  .table-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  table a {
    text-decoration: none;
    color: #333;
  }
}
