.ledger_table {
  fieldset {
    border: 1px solid #ddd !important;
    margin: 0;
    min-width: 0;
    padding: 25px 10px 10px 10px;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 10px;
  }

  legend {
    position: absolute;
    top: -10px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
    min-width: 25%;
    width: auto;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 16px;
    background-color: #ffffff;
  }
  .poll-section {
    button {
      width: 100%;
      margin-top: auto;
    }
    .poll-section {
      height: 100%;
    }
  }
  .radio-section {
    display: flex;
    justify-content: space-around;
  }
  .mg-top-20 {
    margin-top: 20px;
  }
  input[type="radio"] {
    border-color: #1db195;
  }
  input[type="radio"]:checked {
    background-color: #1db195;
  }
  .form-group {
    position: relative;
  }

  .width-90 {
    width: 90%;
  }

  .tooltip-div {
    width: 10%;
    position: relative;
    display: inline-block;
  }
  
  .tooltip-box {
    visibility: hidden;
    min-width: 350px;
    width: 420px;
    background-color: #fff;
    color: #818181;
    text-align: left;
    border: 1px solid #818181;
    border-radius: 4px;
    font-size: 12px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    right: 0;
    table,
    td,
    th {
      border: 1px solid #ebebeb;
      padding: 4px 6px;
    }
    .nowrap {
      white-space: nowrap;
    }
    th {
      background-color: #f9f9f9;
    }
    .black-content {
      color: black;
    }
  }
  .tooltip-trangle {
    visibility: hidden;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #818181;
    position: absolute;
    top: -6px;
  }

  .tooltip-div:hover {
    .tooltip-box {
      visibility: visible;
    }
    .tooltip-trangle {
      visibility: visible;
    }
  }
}
