@import "../../styles/mixin";
@import "../../styles/variable";

.input-group-append {
  position: absolute;
  right: 0px;
  z-index: 0;
  .btn {
    border: 0px;
  }
}

.addable-bank-page {
  .bank-detail-body-wrapper {
    .form-group{
      margin-bottom: 15px;
    }
    .pay-title {
      padding-top: 16px;
      border-top: 1px solid $accent-2-charcoal-10;
      // margin-top: 16px;
      .optional-content {
        color: $silver-black;
      }
    }
    .bank-detail-body {
      border-radius: 4px;
      border: 1px solid $accent-2-charcoal-10;
      padding: 16px;
      margin-top: 12px;
      .bank-row {
        padding: 0 0 16px 0;
        .first-inp-block,
        .second-inp-block {
          width: 50%;
        }
        .first-inp-block {
          margin-right: 16px;
        }
      }
      .second-row {
        padding: 0;
      }
      .nepali-date-picker {
        position: relative;
        background: transparent;
        padding: 0;
        .calendar-wrapper {
          background: #fff none repeat scroll 0 0;
          border-radius: 6px;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          color: #8c8c8c;
          display: block;
          font-family: NotoSans, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: normal;
          height: auto;
          letter-spacing: 0.2px;
          line-height: 1.25em;
          padding: 15px;
          position: absolute;
          text-align: right;
          user-select: none;
          z-index: 5;
          width: 300px;
          bottom: 41px;
          .icon {
            top: 20%;
            right: 0;
          }
        }
      }
    }
    .remove-btn {
      left: unset;
      right: -25px;
      bottom: 5px;
    }
  }
}
.bank-detail-page {
  position: relative;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  padding: 16px;
  margin-top: 12px;
}

.bank-details-head{
  .content{
    margin-top: 10px;
  }
}
