@import "../../styles/mixin";
@import "../../styles/variable";

.switch-radio-section {
  .block-title {
    line-height: 24px;
    letter-spacing: 0.25px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .btn-primary {
    color: $sec-mint-100;
    background-color: $semantics-white;
    border: none;
    font-size: 12px;
    font-weight: 500;
    min-width: 45px;
    padding: 6px 16px 6px 12px;
    letter-spacing: 0.4px;
    border-radius: 4px !important;
    cursor: pointer;
    position: relative;
    line-height: 20px;
    svg {
      fill: $accent-1-deep-indigo-100;
    }
  }

  input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 100%;
    border-radius: 50%;
    opacity: 0;
  }

  .btn-group {
    border: 1px solid $sec-mint-100;
    border-radius: 4px;
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    color: $semantics-white;
    background-color: $sec-mint-100;
    border-radius: 50px;
    transition: 1s;
    left: 0;
    svg {
      fill: $semantics-white;
    }
  }

  .btn-primary:hover {
    background: transparent;
  }
}
