@import "../../styles/variable";
@import "../../styles/mixin";

.smallWidth {
  .bottom-sheet-section {
    .title-section {
      padding: 0 24px 24px 24px;
      align-items: center;
      .extra-small-title {
        color: $dark-text-color;
        margin: 0 auto;
      }
      .cancel-icon {
        img {
          height: 15px;
          width: 15px;
        }
      }
    }
    .customer-modal,
    .new-product {
      padding: 0 24px 15px 24px;
    }
  }
  .react-modal-sheet-container {
    height: 90vh;
    max-height: 75vh;
    overflow: scroll;
  }
}
