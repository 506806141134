@import "./mixin";
@import "./variable";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

p,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

.test {
  margin: 30px;
}
body {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;

  ::-webkit-scrollbar {
    width: 4px;
    background: $semantics-white;
  }

  ::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
  }
}
.mg-btm-0 {
  margin-bottom: 0;
}
.ReactModal__Body--open {
  overflow: hidden;
}
.ReactModal__Content {
  border: 0 !important;
  overflow: unset !important;
  margin: 0 auto;
  background: transparent !important;
}

.custom-card {
  background-color: $semantics-white;
  box-shadow: $card-shadow;
  padding: 20px;
  border-radius: 5px;
}
.d-flex {
  @include d-flex;
}
.d-space-between {
  @include d-space-between;
}
.d-end {
  @include d-flex-end;
  justify-content: flex-end;
}
.custom-react-table {
  table {
    th {
      white-space: nowrap;
      padding: 10px 12px;
    }
  }
}
.d-center {
  @include d-center;
}
.d-start {
  @include d-flex-start;
  justify-content: flex-start;
}
.d-vertical-center {
  @include d-vertical-center;
}
.d-vertical-start {
  @include d-vertical-start;
}
.d-vertical-end {
  @include d-vertical-end;
}
.large-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.15px;
  color: $semantics-black;
}
.p-0 {
  margin-bottom: 0 !important;
}

.small-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.25px;
  color: $semantics-black;
}
.note-text {
  color: $accent-2-charcoal-50;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
}

.extra-small-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.25px;
  color: $semantics-black;
}
.block-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.5px;
  color: $semantics-black;
}
.content {
  @include content-style;
}
.small-content {
  @include content-style;
  font-size: 12px;
  letter-spacing: 0.4;
}
.extra-small-content {
  @include content-style;
  font-size: 10px;
  letter-spacing: 1.5px;
}
.input-label {
  @include label-style;
  font-size: 12px;
}
.input-field {
  @include input-style;
  padding: 8px;
}
.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  .search-icon {
    position: absolute;
    margin: 0 8px 1px 13px;
  }
  .input-field {
    padding-left: 35px;
  }
  .data-array {
    width: 100%;
    .data-wrapper {
      width: 100%;
      .data-lists {
        width: 100%;
      }
    }
  }
}

.horizontal-tab {
  margin-bottom: 12px;
  border-bottom: 1px solid $charcoal-25;
  .HorizontalTabButton_active__8RWiF {
    color: $sec-mint-100 !important;
  }
}
.light-primary {
  border: 1px solid $sec-mint-100;
  color: $sec-mint-100;
  box-shadow: $card-shadow;
  font-size: 12px !important;
}
.light-secondary {
  border: 1px solid transparent;
  color: $accent-1-deep-indigo-100;
  background-color: $accent-1-deep-indigo-5;
  box-shadow: $card-shadow;
  font-size: 12px;
  padding: 8px 28px;
}
.main-button {
  border: 1px solid transparent;
  color: $semantics-white;
  background-color: $sec-mint-100;
  box-shadow: $card-shadow;
  font-size: 12px !important;
}
.line-primary {
  @extend .light-primary;
  border: 0;
  text-decoration: underline;
  box-shadow: none;
  font-size: 12px !important;
}

.mr-16 {
  margin-right: 16px;
}

button {
  @include btn-style;
}
button {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &:disabled:hover {
    opacity: 0.5;
  }
  &:hover {
    opacity: 0.8;
  }
}

.remove-block {
  position: relative;
  .remove-btn {
    position: absolute;
    bottom: -36px;
    left: 3px;
    padding: 0;
    background: transparent;
  }
  .remove {
    fill: $semantics-black;
  }
  &:hover {
    .remove {
      fill: $semantics-red-dark;
    }
  }
}

.check-box-area {
  width: fit-content;
  input[type="checkbox"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid $sec-mint-100;
    border-radius: 4px;
    outline: none;
    margin-right: 12px;
    position: relative;
  }

  input[type="checkbox"]:checked {
    border: 1px solid $sec-mint-100;
    background-color: $sec-mint-100;
  }
}
input[type="checkbox"]:checked::after {
  color: #fff;
  content: "✓";
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-right {
  padding: 32px 35px;
  border-top: 1px solid $charcoal-10;
}
.footer-space {
  padding: 32px 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.col-md-12 {
  width: 100% !important;
}

.col-md-11 {
  width: 91.66% !important;
}

.col-md-10 {
  width: 83.33% !important;
}

.col-md-9 {
  width: 74.99% !important;
}

.col-md-8 {
  width: 66.66% !important;
}

.col-md-7 {
  width: 58.33% !important;
}

.col-md-6 {
  width: 50% !important;
}

.col-md-5 {
  width: 41.66% !important;
}

.col-md-4 {
  width: 33.33% !important;
}

.col-md-3 {
  width: 25% !important;
}

.col-md-2 {
  width: 16.66% !important;
}

.col-md-1 {
  width: 8.33% !important;
}

.col-md-0 {
  width: 0;
}

.error {
  color: $deletColor;
  font-size: 10px;
  margin-top: 5px;
}

.error-input {
  border: 1px solid $deletColor;
  &:focus {
    border: 1px solid $deletColor;
  }
}

.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

.option li {
  padding: 0 6px;
  margin: 0 0;
  padding: 0 10px;
}

.option li:hover {
  background-color: #39f;
  color: white;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.small {
  font-weight: 400;
}

.center-align {
  text-align: center;
}
.end-align {
  text-align: end;
}

.popup-space {
  padding: 0 35px;
}
.card-80 {
  background-color: #fff;
  width: 100%;
  padding: 20px;
  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 20px;
  overflow: hidden;
}
.card-section {
  color: #fff;
  box-shadow: 0px 4px 4px 0px #00000017;
  border-radius: 4px;
  padding: 24px;
  border: 0;
  margin-bottom: 30px;
}
.head-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}
.mid-section {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.green-bg {
  background-color: #1db195;
}
.blue-bg {
  background-color: #29b6f6;
}
.orange-bg {
  background-color: #f2a74b;
}
.pink-bg {
  background-color: #e9427a;
}
.purple-bg {
  background-color: #8a0fb7;
}
.light-blue-bg {
  background-color: #29d1f6;
}
.yellow-bg {
  background-color: #ffb800;
}
.gray-bg {
  background-color: gray;
}
.ellipse {
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.HorizontalTabButton_horizontal-tab-btn__Urt-6 {
  font-size: 16px !important;
}

.table-head {
  font-size: 12px;
  color: #666666;
}
.auto-complete-search-wrap {
  width: 100%;
  height: 38px;
  position: relative;
  .sc-eCYdqJ,
  .bdHMCv {
    .wrapper {
      @include input-style;
      padding: 0;
      position: absolute;
      z-index: 0;
      &:hover {
        box-shadow: none;
      }
      .sc-hKMtZM {
        padding: 8px 16px;
        min-height: unset;
        .fASA-Dv {
          margin: 0;
        }
        input {
          background: $semantics-white;
          font-size: 12px;
          font-weight: 400;
          text-transform: capitalize;
          color: $semantics-black;
          &::placeholder {
            color: $accent-2-charcoal-50;
          }
        }
        .clear-icon {
          display: unset;
        }
      }
      .selected {
        cursor: pointer;
      }
    }
  }
}

.password-area {
  position: relative;
  .eye-section {
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
}
.product-list-page {
  .customer-react-table {
    .search-select {
      display: flex;
      justify-content: end;
    }
  }
}
.customer-react-table {
  .search-select {
    display: flex;
    align-items: center;
  }
}
.note-wrapper {
  margin-right: 20px;
  width: 400px;
  textarea {
    resize: none;
  }
}
.mg-btm-10 {
  margin-bottom: 10px;
}
td {
  vertical-align: middle;
}
.action-section {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    z-index: 10;
  }
  .dropdown-item {
    // width: 120px !important;
    padding: 6px 20px !important;
    .icon {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}
// all dropdown css
.dropdown-toggle {
  font-size: 17px;
}

.dropdown-menu {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none;
  margin-top: 4px !important;
  z-index: 10;
  .show {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .dropdown-item {
    align-items: center;
    margin: 0;
    background-color: #fff;
    color: #7a7a7a;
    padding: 10px 20px;
    width: 200px;
    position: relative;
  }
  .dropdown-item:hover {
    background-color: #f1f1f1;
    color: #000;
    svg {
      path {
        fill: #000;
      }
    }
  }
}
.dropdown-item {
  font-weight: 400;
  .icon {
    margin-right: 10px;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  .btn:first-child:active {
    background-color: unset;
    color: unset;
  }
  .btn:first-child:hover {
    color: #1db195;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: #fff;
    background-color: #1db195;
    svg {
      path {
        color: #fff;
      }
    }
  }
}

///tab btn

.tab-btn {
  border: 1px solid #1db195;
  border-radius: 4px;
  display: flex;
  width: fit-content;
  margin-bottom: 20px;
  .unactive {
    font-size: 12px;
    align-items: center;
    display: flex;
    color: #1db195;
    font-weight: 500;
    cursor: pointer;
    padding: 6px 16px;
    transition: 0.7s;
  }
  .active-bg {
    border-radius: 4px;
    background-color: #1db195;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
    color: white;
    padding: 6px 16px;
    transition: 0.7s;
  }
}
.mg-btn-20 {
  margin-bottom: 20px;
}

// date picker css
.nepali-date-picker {
  .calendar-wrapper {
    td {
      color: #666666 !important;
      font-size: 14px !important;
    }
    table,
    td,
    th {
      border: none !important;
    }
    .selected {
      color: white !important;
    }
    tbody {
      height: 40px !important;
      width: 40px !important;
    }
  }
  .calendar-controller {
    .icon-today {
      position: unset !important;
    }
  }
}

//css for table loader only for report
.loder-wrapper {
  position: relative;
  height: 70px;
  .pos-center {
    position: absolute;
    top: calc(36%);
    left: 50%;
  }
}
.footer-btn-section {
  display: flex;
  justify-content: end;
  margin-top: 20px;

  .btn-section {
    width: fit-content !important;
  }
}
.check-fields {
  align-items: center;
  margin-right: 77px;
  display: flex;
  height: 100%;
}

.calendar-nepali{
  padding: 6px 8px !important;
}
