.btn-section {
  display: flex;
  .btn-fix {
    position: relative;
  }
  .pos-center {
    position: absolute;
    top: unset;
    left: unset;
  }
  .isloading {
    height: 38px;
    border: 1px solid transparent;
    background-color: #1db195;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
    min-width: 120px;
  }
}
