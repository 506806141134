@import "../../styles/mixin";
@import "../../styles/variable";

.admin-page {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
  }
  .form-group {
    margin-bottom: 15px;
    position: relative;
    .eye-section {
      position: absolute;
      top: 33px;
      right: 25px;
    }
  }
  .input-field {
    position: relative;
  }

  .img-section {
    border: 1px solid #bfccd2;
    border-radius: 4px;
    margin-top: 10px;
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    .logo-section {
      width: 150px;
      height: 150px;
      position: relative;

      object-fit: cover;
      .logo {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .cross {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      background-color: red;
      color: #fff;
      padding: 2px;
      padding-top: 0;
      border-radius: 50%;
    }
    .cross:hover {
      background-color: rgb(209, 22, 22);
      color: #fff;
    }
  }
  .btn-right {
    margin-top: 15px;
  }
}
