.dailyReportPage {
  .customer-react-table {
    th:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #f9f9f9;
    }
    th:nth-child(2) {
      position: sticky;
      left: 35px;
      z-index: 1;
      background-color: #f9f9f9;
      border-bottom: 1px solid #ebebeb;
    }
    td:first-child {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: white;
    }
    td:nth-child(2) {
      position: sticky;
      left: 35px;
      z-index: 1;
      background-color: white;
      border-bottom: 1px solid #ebebeb;
    }
    .form-group {
      margin-right: 15px;
    }
    .customer-react-table {
      width: 100%;
      overflow-x: hidden;
      .overflowTable {
        min-height: 100px;
        position: relative;
        th {
          white-space: nowrap;
        }
      }
    }
  }
}
