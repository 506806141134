.profile-page {
  .tag-wrapper {
    margin-bottom: 40px;
    margin-top: 10px;

    border-bottom: 2px solid #8098a5;
    .chip-tag {
      color: #8098a5;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
    .active {
      border-bottom: 2px solid #1db195;
      color: #1db195;
    }
  }
}
