@import "../../styles/variable";
.balance-sheet {
  .text-right {
    text-align: right;
  }
  .check-field {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .text-center {
    text-align: center;
  }
  p {
    margin: 0;
  }
  .top-head {
    text-align: center;
    margin: 15px 0;
  }
  .mg-auto {
    margin: auto;
  }
  .bold-content {
    color: black;
    font-size: 14px;
    font-weight: 500;
  }
  .mgTop-auto {
    margin-top: auto;
  }
  .small-text {
    font-size: 16px;
  }
  .black-text {
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }
  .width-50 {
    width: 50%;
  }
  .black-content {
    color: #151515 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .content {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
    font-style: italic;
  }
  .content-title {
    font-size: 12px;
    font-weight: 500;
    color: #666666;
  }

  .btm-page {
    position: sticky;
    bottom: 10px;
    width: 100%;
    border-top: 1px solid #dcdcdc;
  }
  table {
    border: 1px solid #dcdcdc;
    margin-bottom: 0;

    td {
      padding: 8px 16px;
      cursor: pointer;
    }
    .border-right {
      border-right: 1px solid #dcdcdc;
    }
  }
  .table-header {
    background-color: #f6f6f6;
  }
  .btm-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.5rem;
  }
  .table-page {
    border: 1px solid #e0e0e0;
    padding: 12px 16px;
    border-radius: 8px;
    height: 100%;
    position: relative;
  }
  .footer-content {
    color: $sec-mint-100;
    font-size: 14px;
    font-weight: 600;
  }
  .border-bottom {
    border-bottom: 1px solid #dcdcdc;
  }
  .table-body {
    min-height: 200px;
  }
  .main-table {
    input[type="checkbox"] {
      display: none;
    }
    th {
      padding: 8px 16px;
      background-color: #fff;
    }

    td {
      vertical-align: top;
      padding: 0;
      td {
        padding: 8px 16px;
      }
    }
  }
  .width-60 {
    width: 60%;
  }
  .footer-table {
    width: 100%;
    border-top: none;
    border-bottom: none;
    td {
      // border: 1px solid #dcdcdc;
      vertical-align: bottom;
      border: none !important;
    }
  }
  .small-table {
    border-bottom: none;
    border-top: none;
    border-right: none;

    th {
      background-color: #f6f6f6;
      border-bottom: 1px solid #dcdcdc;
    }
    td {
      border: none;
    }
  }

  .content-main-title {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .dontShow {
    display: none;
  }
  .show {
    .padd-box {
      display: flex;
    }
  }
  .extra-padd {
    padding: 0 12px !important;
  }
  .child-item {
    color: red !important;
  }
}
