@import "../../styles/variable";
@import "../../styles/mixin";

.dotted-line {
  border: 1px solid black;
  width: 94%;
  text-align: center;
}

.bill-loader {
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 20%;
  }
}

.new-receipt {
  margin: 0px !important;
}

.receipt {
  margin: 28px;

  p {
    margin-bottom: 0;
  }
  .btn-right {
    margin-top: 0;
  }
  .copy-text {
    display: none;
  }
  .full-table {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
    margin-top: 30px;
    table {
      margin-bottom: 0;
      .particular-width {
        width: 40%;
      }
    }
  }
  .end-col {
    text-align: end;
  }
  .name-head {
    width: 30%;
  }
  .table-borderless {
    margin-bottom: 0;
    th {
      padding: 5px;
    }
    td {
      padding: 5px;
    }
  }
  .center-align {
    th {
      padding: 2px;
    }
  }
  .table-bordered {
    th {
      border: 1px solid black;
      font-size: 10px;
    }
    td {
      border: 1px solid black;
      border-bottom: 0px;
      border-top: 0px;
    }
    tr {
      border: 0px;
    }
  }
  .padd-5 {
    padding: 2px 5px;
    border: 1px sold black;
  }
  .content {
    font-weight: 400;
    color: $semantics-black;
    margin-top: 2px;
    line-height: 16px;
    font-size: 14px;
  }
  .bold-content {
    font-weight: 500;
    color: $semantics-black;
    margin-top: 2px;
    line-height: 16px;
    font-size: 14px;
    white-space: nowrap;
  }
  .receipt-top {
    margin-bottom: 28px;
    .font-field {
      max-width: 100px;
      margin-left: auto;
      margin-right: 15px;
    }
    .cancel-icon {
      img {
        height: 18px;
        width: 18px;
      }
    }
  }
  .receipt-section {
    margin: 20px;
    .p-receipt {
      border: 1px solid $sec-mint-100;
      padding: 28px;
    }
    .logo-detail {
      display: flex;
      gap: 15px;
      .small-title {
        margin-bottom: 0px;
      }
      .content {
        line-height: 20px;
      }
    }
    .logo-section {
      height: 60px;
      width: 60px;
      justify-content: center;
      .large-title {
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
      .logo {
        img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          // background: black;
        }
      }
    }
    .receipt-detail-block {
      flex-direction: column;
      .large-title {
        text-align: center;
        font-weight: 500;
        color: $sec-mint-100;
      }
      .small-content {
        color: $silver-black;
      }
      p {
        margin-top: 4px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
    .header-align-center {
      padding: 2px;
    }
  }

  .thermal-receipt-section {
    width: 7cm;
    font-size: 8px;
    margin: 0 auto;
    padding: 10px;
    border: 1px dotted #000;

    .p-receipt {
      .d-flex {
        display: flex;
        justify-content: center;
      }

      .logo-detail {
        text-align: center;
        p {
          margin-bottom: 2px;
        }
      }

      .receipt-detail-block {
        margin-top: 16px;
        .table {
          width: 100%;

          td {
            padding: 0;
            font-size: 8px;
          }

          .text-end {
            text-align: right;
          }
        }
      }

      .full-table {
        margin: 10px 0;
        border-bottom: none;
        .table {
          width: 100%;
          border-collapse: collapse;
          th,
          td {
            font-size: 8px;
            font-weight: normal;
            color: #000;
            line-height: normal;
          }
          th {
            border-bottom: 1px dotted #000;
            border-top: 1px dotted #000;
          }
          thead {
            background-color: transparent;
          }
          td {
            padding: 4px 2px !important;
          }
          th {
            text-transform: capitalize;
            padding: 4px 2px;
          }

          .header-align-center {
            text-align: center;
          }

          .text-end {
            text-align: right;
          }

          .end-col {
            text-align: right;
          }

          .padd-5 {
            padding: 5px;
          }
        }
      }

      .footer-div {
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px dotted #000 !important;
        .receipt-calculation {
          width: 75%;
          .receipt-calculation-block {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
            &:nth-last-child(1) {
              border-top: 1px dotted #000;
            }
          }
        }
      }

      .receipt-info {
        padding: 8px 0;
        .receipt-info-section {
          margin-bottom: 8px;
        }
        .custom-hr {
          width: 100%;
          border-bottom: 1px dotted #000;
          margin: 8px 0;
        }
        .border-bottom-dotted {
          border-bottom: 1px dotted #000;
        }
        .receipt-info-block {
          margin-top: 0px;
          h4 {
            margin-bottom: 5px;
            font-size: 8px;
            font-weight: 400;
          }

          .customers-sign {
            margin-bottom: 10px;
            padding-top: 5px;
            font-size: 10px;
          }
        }
      }
    }
  }

  .receipt-section {
    margin: 20px;
    .p-receipt {
      border: 1px solid $sec-mint-100;
      padding: 28px;
    }
    .logo-detail {
      display: flex;
      gap: 15px;
      .small-title {
        margin-bottom: 0px;
      }
      .content {
        line-height: 20px;
      }
    }
    .logo-section {
      height: 60px;
      width: 60px;
      justify-content: center;
      .large-title {
        font-weight: 500;
        font-size: 28px;
        color: white;
      }
      .logo {
        img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
          // background: black;
        }
      }
    }
    .receipt-detail-block {
      flex-direction: column;
      .large-title {
        text-align: center;
        font-weight: 500;
        color: $sec-mint-100;
      }
      .small-content {
        color: $silver-black;
      }
      p {
        margin-top: 4px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
    .header-align-center {
      padding: 2px;
    }
  }

  .div-flex {
    display: flex;
    align-items: center;
  }
  .pan-box {
    padding-left: 5px;
    margin-left: 5px;
    border-left: 1px solid black;
  }
  .header-wrapper {
    padding-top: 10px;
    border-top: 1px solid $light-border;
    margin-top: 8px;
    margin-bottom: 10px;
    align-items: unset;
    .receipt-header {
      width: 50%;

      @include d-space-between;
      align-items: flex-start;
      .content {
        margin-bottom: 5px;
        list-style: 15px;
      }
      .bold-content {
        margin-bottom: 5px;
        list-style: 15px;
        white-space: nowrap;
      }
      .receipt-header-block {
        width: auto;
        p {
          margin-top: 4px;
        }
        .black-text {
          color: $semantics-black;
          font-weight: 400;
        }
        .vertical-line {
          border-left: 1px solid #818181;
          margin: 0 10px;
          height: 18px;
        }
        .gray-text {
          display: flex;
          color: $accent-2-charcoal-100;
          .light-gray-text {
            color: $gray-0;
          }
        }

        .capitalize {
          font-weight: 500;
        }
      }
    }
    .last-block {
      width: auto;
    }
  }
  .sub-text {
    display: flex;
    align-items: center;
  }

  .table {
    thead {
      background: $accent-2-charcoal-pastel;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      color: $small-content;
    }
    tbody {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      color: $semantics-black;
    }
    .header-align-center {
      vertical-align: middle;
      text-align: center;
    }
  }
  .payment-information {
    display: none;
  }
  .footer-div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-border !important;

    .small-content {
      margin-bottom: 4px;
      font-size: 14px;
      color: #000;
      font-weight: 400;
    }

    .width-21 {
      margin-top: 24px;
      padding-left: 10px;
      .customers-sign {
        margin-top: 25px;
      }
    }

    .receipt-calculation {
      width: 29%;
      .text-right {
        text-align: right;
      }
      .receipt-calculation-block {
        @include d-flex;
        gap: 5px;
        padding: 6px 10px;

        &:nth-last-child(1) {
          border-top: 1px solid $light-border;
          .content {
            font-weight: 500;
            color: $semantics-black;
          }
        }
        p {
          width: 50%;
          margin: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .receipt-info {
    padding: 16px 0;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-last-child(1) {
      border-bottom: 0;
    }
    .receipt-info-section {
      display: flex;
      margin-bottom: 24px;
    }
    .block-title {
      margin-bottom: 4px;
    }
    .receipt-info-block {
      margin-top: 16px;
      .customers-sign {
        padding-top: 25px;
      }
    }
    .receipt-due {
      margin-left: auto;
      width: 23%;
      .receipt-due-block {
        @include d-flex;
        background: $accent-2-charcoal-5;
        padding: 8px 16px;
        border-radius: 4px;
        border-top: 1px solid $light-border;
        .p-0 {
          margin-bottom: 0 !important;
        }
        .content {
          font-weight: 500;
          width: 50%;
          color: $semantics-black;
        }
      }
    }
    .receipt-issue {
      margin-left: auto;
      width: 20%;
      .block-title {
        margin-bottom: 8px;
      }
      .small-content {
        margin-bottom: 4px;
        color: $silver-black;
      }
      .gray-text {
        color: $accent-2-charcoal-100;
        .light-gray-text {
          color: $gray-0;
        }
      }
      .m-0 {
        margin: 0;
      }
    }
    .payment-information {
      // margin-bottom: 75px;
      .small-content {
        margin-bottom: 4px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
    .receipt-footer {
      text-align: center;
    }
  }
  .print-title {
    display: none;
  }

  .tag-wrapper {
    margin: 30px 0 16px 0;
    .chip-tag {
      background-color: $semantics-white;
      color: $dark-text-color;
      padding: 6px 12px;
      border: 1px solid $accent-1-deep-indigo-5;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
      border-radius: 24px;
      cursor: pointer;
    }
    .ledger-bar {
      margin: 0 8px;
    }
    .active {
      border: 1px solid $sec-mint-100;
      color: $sec-mint-100;
    }
    .searchable-name {
      padding-top: 0;
      margin-bottom: 10px;
    }
  }
}

.nepali-text {
  font-family: "Noto Sans Devanagari", sans-serif;
  th {
    font-size: 14px !important;
  }
  .bold-content {
    font-weight: bold !important;
  }
}

@media print {
  @page {
    size: auto;
    margin: 20px;
    page-break-after: always;
    page-break-inside: avoid;
  }
  .thermal-receipt-section {
    @page {
      size: 7cm auto;
    }
  }
  .receipt-section {
    @page {
      size: A4 portrait;
    }
  }

  .page:not(:last-child) {
    page-break-after: always;
  }
  .copy-text {
    display: block;
  }
  .full-table {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
    margin-top: 30px;
    table {
      margin-bottom: 0;
      .particular-width {
        width: 40%;
      }
    }
  }
  tr {
    align-items: center;
  }
  p {
    margin-bottom: 0;
  }
  .btn-right {
    display: none;
  }
  .div-flex {
    display: flex;
    align-items: center;
  }
  .footer-div {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $light-border !important;

    .small-content {
      margin-bottom: 4px;
      font-size: 12px;
      color: #000;
      font-weight: 400;
    }
  }
  .payment-information {
    display: none;
  }
  .table-bordered {
    th {
      border: 1px solid black;
      font-size: 10px;
    }
    td {
      border: 1px solid black;
      border-bottom: 0px;
      border-top: 0px;
    }

    tr {
      border: 0px;
    }
  }
  .width-21 {
    margin-top: 35px;
    padding-left: 15px;
    .customers-sign {
      margin-top: 20px;
    }
  }
  .mg-footer {
    margin-top: 20px;
  }
  .table-borderless {
    margin-bottom: 0;
    .bold-content {
      text-align: left !important;
      white-space: nowrap;
    }
    th {
      padding: 5px !important;
    }
    td {
      text-align: left;
      padding: 5px;
    }
  }
  .thermal-receipt-section {
    width: 7cm;
    font-size: 8px;
    margin: 0 auto;

    .p-receipt {
      .d-flex {
        display: flex;
        justify-content: center;
      }

      .logo-detail {
        text-align: center;
        p {
          margin-bottom: 2px;
        }
      }

      .receipt-detail-block {
        margin-top: 24px;
        .table {
          width: 100%;

          td {
            padding: 0;
            font-size: 8px;
          }

          .text-end {
            text-align: right;
          }
        }
      }

      .full-table {
        margin: 10px 0;
        border-bottom: none;
        .table {
          width: 100%;
          border-collapse: collapse;
          th,
          td {
            font-size: 8px;
            font-weight: normal;
            color: #000;
            line-height: normal;
          }
          th {
            border-bottom: 1px dotted #000;
            border-top: 1px dotted #000;
          }
          thead {
            background-color: transparent;
          }
          td {
            padding: 4px 2px !important;
          }
          th {
            text-transform: capitalize;
            padding: 4px 2px;
          }

          .header-align-center {
            text-align: center;
          }

          .text-end {
            text-align: right;
          }

          .end-col {
            text-align: right;
          }

          .padd-5 {
            padding: 5px;
          }
        }
      }

      .footer-div {
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px dotted #000 !important;
        .receipt-calculation {
          width: 75%;
          border-top: 1px dotted #000;
          .receipt-calculation-block {
            display: flex;
            justify-content: space-between;
            padding: 4px 0;
          }
          .border-top-dotted {
            border-top: 1px dotted #000;
          }
        }
      }

      .receipt-info {
        padding: 8px 0;
        .receipt-info-section {
          margin-bottom: 8px;
        }
        .custom-hr {
          width: 100%;
          border-bottom: 1px dotted #000;
          margin: 8px 0;
        }
        .border-bottom-dotted {
          border-bottom: 1px dotted #000;
        }
        .receipt-info-block {
          margin-top: 0px;
          h4 {
            margin-bottom: 5px;
            font-size: 8px;
            font-weight: 400;
          }

          .customers-sign {
            margin-bottom: 10px;
            padding-top: 5px;
            font-size: 10px;
          }
        }
      }
    }
  }

  .content {
    font-weight: 400;
    color: $semantics-black;
    margin-top: 2px;
    line-height: 10px;
    font-size: 12px;
  }
  .bold-content {
    font-weight: 500;
    color: $semantics-black;
    margin-top: 2px;
    line-height: 16px;
    font-size: 12px;
    white-space: nowrap;
  }

  .print-page {
    // .thermal-receipt-section {
    //   page-break-after: always;
    //   page-break-inside: avoid;
    //   p, td {
    //     font-size: 10px;
    //   }
    // }

    .receipt-section {
      .p-receipt {
        border: 1px solid $sec-mint-100 !important;
        padding: 20px;
      }
      .logo-detail {
        display: flex;
        gap: 15px;
        // p{
        //   margin-bottom: 0;
        // }
        .small-title {
          margin-bottom: 0px;
        }
        .pan-box {
          padding-left: 5px !important;
          margin-left: 5px !important;
          border-left: 2px solid black;
        }
        .content {
          font-weight: 400;
          color: $semantics-black;
        }
      }
      .logo-section {
        justify-content: center;
        height: 50px;
        width: 50px;
        background-color: #1db195 !important;
        border-radius: 50%;
        .large-title {
          font-weight: 500;
          font-size: 24px;
          color: white;
        }
        .logo {
          img {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            // background: black;
          }
        }
      }
      .print-title {
        display: block;
      }

      .receipt-detail-block {
        flex-direction: column;
        .large-title {
          text-align: center;
          font-weight: 500;
          color: $sec-mint-100;
          .small-content {
            color: $silver-black;
            margin-bottom: 7px;
          }
          p {
            margin-top: 4px;
            margin-bottom: 7px !important;

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .header-wrapper {
      padding-top: 10px;
      border-top: 1px solid $light-border !important;
      margin-top: 8px;
      margin-bottom: 10px;
      align-items: unset;
      .receipt-header {
        @include d-space-between;
        align-items: flex-start;
        .receipt-header-block {
          width: auto;
          p {
            margin-top: 4px;
          }
          .black-text {
            color: $semantics-black;
            font-weight: 400;
          }
          .gray-text {
            color: $accent-2-charcoal-100;
            .light-gray-text {
              color: $gray-0;
            }
          }
          .capitalize {
            font-weight: 500;
          }
          .div-flex {
            display: flex;
            align-items: center;
          }
        }
      }
      .last-block {
        width: auto;
      }
    }

    .table {
      border: 1px sold black;
      .name-head {
        width: 30%;
      }
      thead {
        background: $accent-2-charcoal-pastel;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        color: $small-content;
      }
      tbody {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.4px;
        color: $semantics-black;
      }
      .table-bordered {
        th {
          border: 1px solid black;
        }
        td {
          border: 1px solid black;
        }
      }
      .header-align-center {
        vertical-align: middle;
        white-space: nowrap;
      }
      th {
        padding: 2px;
        text-align: center;
      }
      .padd-5 {
        padding: 2px 5px;
        text-align: center;
      }
      .end-col {
        text-align: end;
        width: 10%;
      }
      .name-sec {
        text-align: left;
      }
    }
    .receipt-calculation {
      margin-left: auto;
      width: 33%;
      .text-right {
        text-align: right;
      }
      .block-title {
        font-size: 12px;
      }
      .receipt-calculation-block {
        @include d-flex;
        gap: 5px;
        padding: 2px 16px;

        &:nth-last-child(1) {
          border-top: 1px solid $light-border !important;
          .content {
            font-weight: 500;
            color: $semantics-black;
          }
        }

        p {
          width: 50%;
          margin: 0;
        }
      }
    }

    .receipt-info {
      padding: 16px 0;
      &:nth-child(1) {
        padding-top: 0;
      }
      &:nth-last-child(1) {
        border-bottom: 0 !important;
      }
      .receipt-info-section {
        display: flex;
        margin-bottom: 16px;
      }
      .block-title {
        font-size: 12px;
        margin-bottom: 4px;
      }
      .receipt-info-block {
        margin-top: 10px;
        .customers-sign {
          padding-top: 20px;
        }
      }
      .receipt-due {
        margin-left: auto;
        width: 35%;
        .receipt-due-block {
          @include d-flex;
          background: $accent-2-charcoal-5;
          padding: 4px 16px;
          border-radius: 4px;
          border-top: 1px solid $light-border !important;
          .p-0 {
            margin-bottom: 0 !important;
          }
          .content {
            font-weight: 500;
            width: 50%;
            margin-bottom: 0;
            color: $semantics-black;
          }
        }
      }
      .receipt-issue {
        margin-left: auto;
        width: 25%;
        .block-title {
          margin-bottom: 8px;
        }
        .small-content {
          margin-bottom: 4px;
          font-size: 12px;
          color: $silver-black;
        }

        .m-0 {
          margin: 0;
        }
      }
      .payment-information {
        .small-content {
          margin-bottom: 4px;
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
      .receipt-footer {
        text-align: center;
      }
    }
  }
}
