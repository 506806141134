.access-denied-page {
    padding: 20px;
  .top-section {
    display: flex;
    justify-content: center;
  }
  .big-ellipse {
    position: relative;
    padding: 20px;
    background-color: #1db195;
    border-radius: 50%;
    color: #fff;
  }
  .small-title {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
  }
  .text-section {
    text-align: center;
  }
  .mid-page {
    width: 400px;
    margin: auto;
  }
  .sub-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #666666;
    justify-content: center;
  }
  .error-title {
    font-size: 16px;
  }
  .btn-mid {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
