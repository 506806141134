.password-page {
  .title-box {
    width: 80%;
  }
  .gray {
    color: #666666;
  }
  .btn-right {
    padding: 0;
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 30px;
  }
  .title-box {
    margin: auto;
    text-align: center;
  }
  .extra-small-title{
    text-align: center;
  }
}
