
.opening-edit-page{

    .parent{
        display: flex;
justify-content: space-between;
gap: 10px;
.child{
    width:100%
}
    }

.button-content{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

}