@import "../../styles/mixin";
@import "../../styles/variable";

.new-select {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  .each-select-child {
    width: 80%;
  }
}

.main-button {
  min-width: 120px;
}

.small-title {
  margin-bottom: 0;
}

.add-bill {
  .mg-top-10 {
    margin-top: 20px;
  }
  .nepali-date-picker {
    position: relative;
    background: transparent;
    padding: 0;
    z-index: 1;
    box-shadow: unset;
    .form-control {
      z-index: 1;
      font-size: 12px;
      font-weight: 400;
      padding: 8px;
      border-radius: 4px;
    }
    .form-control:focus {
      box-shadow: unset;
      border-color: #1db195;
    }

    .calendar-wrapper {
      padding: 20px;
      color: #8c8c8c;
      background-color: #fff;
      display: block;
      font-size: 14px;
      position: absolute;
      z-index: 1;
      width: 350px;
      right: 0;
      border-radius: 6px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
    }
  }

  .bill-title {
    font-weight: 600;
    letter-spacing: 0.15px;
    line-height: 27px;
  }
  .bill-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tab-btn {
      border: 1px solid #1db195;
      border-radius: 4px;
      display: flex;
    }

    .unactive {
      font-size: 12px;
      align-items: center;
      display: flex;
      color: #1db195;
      font-weight: 500;
      cursor: pointer;
      padding: 6px 16px;
      transition: 0.7s;
    }
    .active-bg {
      border-radius: 4px;
      background-color: #1db195;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
      color: white;
      padding: 6px 16px;
      transition: 0.7s;
    }
    .bill-no {
      text-transform: uppercase;
      &::placeholder {
        text-transform: none;
      }
    }
    .input-label {
      margin-bottom: 0;
    }
  }
  .date-section {
    width: 15%;
    .input-field {
      width: 100%;
    }
  }
  .transaction-date {
    margin-left: auto;
    margin-right: 16px;
  }

  .bill-detail {
    margin: 32px 0;
    .css-13cymwt-control {
      .css-1jqq78o-placeholder {
        font-size: 12px;
      }
    }
    .customer-title {
      padding-bottom: 12px;
      // margin-bottom: 16px;
      // border-bottom: 1px solid $accent-2-charcoal-10;
      .c-title {
        color: $dark-text-color;
        font-weight: 500;
      }
      .c-button {
        span {
          margin-right: 8px;
        }
      }
    }
  }

  .search-option {
    .input-field {
      text-transform: capitalize;
    }
  }

  .toggle-section {
    .add-product {
      margin: 32px 0 12px 0;
      .form-title {
        color: $dark-text-color;
      }
    }
    .c-button {
      span {
        margin-right: 8px;
      }
    }
  }
  .nepali-calendar-wrapper {
    .calendar {
      right: 0 !important;
    }
  }
}

.mobile-view-bill {
  .add-bill {
    .custom-card-background {
      border-radius: 4px;
      background-color: $card-bg;
      padding: 12px;
      margin-bottom: 16px;
      .mb-bill-title {
        .bill-no,
        .date-section {
          width: 100%;
          margin-top: 16px;
        }
      }
      .search-option {
        margin-top: 16px;
      }
      .first-search-box {
        margin-top: 8px;
      }
      .add-product {
        .c-button {
          span {
            margin: 0;
          }
        }
      }
      .new-line,
      .c-button {
        width: 100%;
      }
      .new-line {
        margin-top: 12px;
      }
      .custom-card {
        padding: 0;
        margin-top: 12px;
        .added-product {
          border-bottom: 1px solid $light-border;
          padding: 8px 12px;

          .small-content {
            color: $small-content;
          }
          .balance-block {
            width: 25%;
          }
        }
        .product-body {
          padding: 8px 12px;
          .d-space-between {
            align-items: flex-start;
          }
          .block-title {
            font-weight: 400;
          }
          .product-detail {
            .extra-small-content {
              margin-top: 4px;
              line-height: 15px;
            }
          }
          .second-section {
            width: 20%;
          }
          .remove-block {
            .remove-btn {
              bottom: -19px;
              left: -19px;
            }
          }
        }
      }
      .add-section {
        .c-button {
          span {
            margin: 0;
          }
        }
      }
    }
    .total-card-wrapper {
      border: 1px solid $light-border;
      border-radius: 4px;
      .line-segment {
        margin-bottom: 16px;
        .content,
        .input-space {
          width: 50%;
          font-weight: 400;
        }
        .content {
          color: $semantics-black;
        }
        .check-box-area {
          .content {
            width: 100%;
          }
          input[type="checkbox"] {
            width: 25px;
            margin-right: 5px;
          }
        }
      }
      .no-space {
        margin: 0;
      }
      .total-card {
        padding: 8px 24px;
      }
      .total-bottom {
        padding: 8px 24px;
        border-top: 1px solid $light-border;
        background-color: $accent-2-charcoal-pastel;
      }
    }
    .bank-detail-wrapper {
      position: relative;
      border: 1px solid $light-border;
      border-radius: 4px;
      margin: 12px 0;
      .bank-detail {
        padding: 12px 16px;
        border-bottom: 1px solid $light-border;
        .cash-title {
          margin-bottom: 12px;
        }
      }
      .credit-detail {
        border-bottom: 0;
      }
    }

    .toggle-section {
      .switch-radio-section {
        margin: 8px 0 16px 0;
      }
    }
    .note-area {
      margin: 0;
    }
  }
}
