@import "../../styles/mixin";
@import "../../styles/variable";

.ledger-page {
  .line-default{
    color: #b5b4b4 !important;
    cursor: not-allowed;
    text-decoration: underline;
  }
  .mg-right-10{
    margin-right: 10px;
  }
 
  .custom-react-table {
    .tablehead {
      text-transform: uppercase;
      th {
        letter-spacing: 0.4px;
      }
    }
  }
}
