
@import "../../styles/variable";
@import "../../styles/mixin";

.opennig-page{
  .grand-total{  
    display:  flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    .extra-small-title{
      margin: 0 15px 0;
    }
    .form-control{
      width: 200px;
  
    }
  }
 
  .delete-icon{
    background: none;
    .img{
      height: 24px;
    }  
  
  }
  .select-field{
    margin-bottom: 32px;
  }
  .parent-search{
    display: flex;
    justify-content: flex-end;
    .search-feild{
      min-width: 25%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

}