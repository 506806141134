@import "../../styles/mixin";
@import "../../styles/variable";

.business-info-form {
  .business-body-data {
    padding: 0 35px 15px 35px;
    .input-field {
      margin-bottom: 24px;
    }

    .logo-field {
      .input-field {
        padding: 5px 16px;
      }
    }
    .data-field {
      margin-bottom: 24px;
      .input-field {
        margin-bottom: 0;
      }
    }

    .no-bottom {
      margin-bottom: 0;
      .input-field {
        margin-bottom: 0;
      }
    }

    fieldset {
      border: 1px solid $field-set;
      padding: 0 20px;
      border-radius: 4px;
      position: relative;
      margin-top: 20px;
      padding: 20px 18px;
      legend {
        position: absolute;
        background: $semantics-white;
        top: -14px;
        width: fit-content;
        padding: 0 10px;
        font-weight: 400;
        color: $silver-black;
        left: 8px;
      }
    }
    .business-field-set {
      margin-bottom: 40px;
    }
    .ird-field-set {
    }
  }
}
