@import "../../styles/mixin";
@import "../../styles/variable";

.addable-price {
  .price-body {
    margin: 16px 0;
    align-items: flex-start;
    .body-section {
      margin-right: 20px;
      width: 33.33333333%;
      .Select_select__aeuTF {
        color: #000;
      }
    }
    .no-gap {
      margin: 0;
    }
    .remove-block {
      .remove-btn {
        bottom: -25px;
        left: 3px;
        svg {
          height: 19px;
          width: 18px;
        }
      }
      .mobile-position {
        bottom: 150px;
        left: unset;
        right: -7px;
      }
    }
  }
  .mobile-view-body {
    border: 1px solid $light-border;
    padding: 12px 0;
    border-radius: 4px;
    margin-bottom: 16px;

    .role-block {
      margin-bottom: 12px;
    }
    .body-section {
      margin-right: 20px;
      width: 50%;
    }
  }
  .add-button {
    margin: 8px 0;
    img {
      margin: 0 8px 2px 0;
    }
  }
}
