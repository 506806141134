@import "../../styles/mixin";
@import "../../styles/variable";
.loader {
  border: 9px solid #f3f3f3; /* Light grey */
  border-top: 9px solid #689774; /* Dark Green */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spinloader 2s linear infinite;
}
.loader-img {
  width: 46px;
  position: relative;
  top: -63px;
  left: 17px;
}
@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.pos-center {
  position: fixed;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid $sec-mint-dark;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
