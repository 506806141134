.profit-loss-page {
  .text-right {
    text-align: right !important;
  }
  .check-field {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .content-main-title {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000 !important;
    padding: 8px 16px;
  }
  .dontShow {
    display: none;
  }
  .show {
    .padd-box {
      display: flex;
    }
  }
  .width-100 {
    width: 100%;
  }
  .total {
    color: #00bf91 !important;
    font-size: 14px !important;
  }
  .width-80 {
    width: 80%;
  }
  .main-table {
    width: 100%;
    input[type="checkbox"] {
      display: none;
    }
    td {
      text-align: left;
      vertical-align: top;
    }
  }
  .main-table-header {
    padding: 8px 16px;
    border-bottom: none;
    font-size: 14px;
    font-weight: 500;
    th {
      border: none;
    }
  }

  .border-top-0 {
    border-top: none;
  }
  .border-bottom-0 {
    border-bottom: none;
  }
  .vertical-bottom {
    vertical-align: bottom !important;
  }

  .footer-table {
    width: 100%;
    border: none !important;

    td {
      border-bottom: 1px solid #dcdcdc !important;
      padding: 8px 16px;
      border: none !important;
      vertical-align: bottom;
    }
    .border-right {
      border-right: 1px solid #dcdcdc !important;
    }
  }
  .top-head {
    text-align: center;
    margin: 15px 0;
  }
  .mg-auto {
    margin: auto;
  }
  .width-40 {
    width: 40%;
  }
  .width-60 {
    width: 60%;
  }
  .no-border-left {
    border-left: none !important;
  }
  .no-border-right {
    border-right: none !important;
  }

  .tarding-balance-table {
    p {
      margin-bottom: 0px;
    }
    .table-header {
      background-color: #f9f9f9;
      border-bottom: 1px solid #f0f0f0;
    }
    table {
      border-collapse: collapse !important;
      border: 1px solid #f0f0f0;
    }
    td {
      border: none;
    }
    .title-group {
      display: flex;
      justify-content: space-between;
      .black-color {
        text-align: left;
        color: #000 !important;
      }
    }

    .sub-group {
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      .black-color {
        text-align: left;
        color: #666666;
      }
    }
    .bold-content {
      color: black;
      font-size: 14px;
      font-weight: 500;
    }
    .small-text {
      font-size: 16px;
    }
    .black-text {
      color: black;
      font-size: 12px;
      font-weight: 400;
      text-align: center;
    }
    .black-content {
      color: black;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 19px;
    }
    .text-left {
      text-align: left !important;
    }
    .main-table {
      .content {
        text-align: left;
        color: #666666;
        font-style: italic;
      }
    }
    th {
      font-weight: 500;
      font-size: 12px;
      color: #666666;
      border: 1px solid #dcdcdc;
      vertical-align: middle;
      text-align: center;
    }
    td {
      border-bottom: none;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      border: 1px solid #dcdcdc;
    }
    .width-30 {
      width: 30%;
    }
    .width-50 {
      width: 50%;
    }
    .width-35 {
      width: 35%;
    }
    .width-20 {
      width: 20%;
    }
  }
  .table {
    margin-bottom: 0;
  }
  .no-border {
    border: none !important;
  }
  .mini-table {
    width: 100%;
    border: none !important;

    th {
      padding: 8px 16px;
      background-color: #ececec;

      // border-bottom: 1px solid #666666;
    }
    tbody {
      border: none !important;
    }
    td {
      text-align: left !important;
      padding: 8px 16px;
      border: none !important;
    }
  }
}
