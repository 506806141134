.button-content {
  display: flex;
  justify-content: end;
  gap: 8px;
}
.receipt-page {
  padding: 20px;
  .c-button {
    color: #1db195;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 3px;
    text-align: end;
  }
  .content {
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    white-space: nowrap;
    font-size: 12px;
    margin-bottom: 4px;
    text-align: end;
  }
  .bold-content {
    font-weight: 700;
    color: #333333;
    margin-top: 2px;
    line-height: 14px;
    font-size: 12px;
    white-space: nowrap;
  }
  .business-address {
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    white-space: nowrap;
    font-size: 12px;
  }
  .logo-section {
    .logo {
      background-color: #1db195;
      border-radius: 50%;
      margin-right: 12px;
    }
    .small-title {
      margin-bottom: 0;
    }

    .div-flex {
      display: flex;
    }
    .pan-box {
      padding-left: 5px;
      margin-left: 5px;
      border-left: 1px solid #333333;
    }
    img {
      height: unset;
      width: 70px;
    }
  }
  .comment-section {
    margin-top: 30px;
    margin-bottom: 12px;
    .same-section {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .content {
        margin-bottom: 0;
      }
    }
    .text-section {
      width: 100%;
      padding: 0 4px;
      margin-left: 6px;
      border-bottom: 1px solid #999999;
      font-size: 14px;
    }
    .sign-section {
      width: 50%;
      padding: 0 4px;
      margin-left: 6px;
      border-bottom: 1px dashed #999999;
      font-size: 14px;
    }
    .div-flex {
      margin-top: 20px;
      display: flex;
      .content {
        white-space: nowrap;
        margin-bottom: 0;
      }
    }
    .footer-section {
      display: flex;
      margin-top: 40px;
      justify-content: space-between;
    }
    .width-50 {
      width: 50%;
    }
    .justify-end {
      justify-content: end;
    }
    .black-font {
      color: #000;
    }
    .black-dot {
      border-bottom: 2px dotted #000;
    }
  }
}
@media print {
  .button-content {
    display: none;
  }
}
