@import "../../styles/mixin";
@import "../../styles/variable";

.sub-title {
  margin: 8px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid $accent-2-charcoal-10;
  td {
    vertical-align: baseline;
  }

  // .equal-width {
  //   padding: 0 12px;
  // }
  .width-100 {
    .input-field {
      padding: 2px;
    }
  }

  .table-input {
    padding: 4px;
    border-right: 1px solid $accent-2-charcoal-10;
    height: 55px;
    align-items: center;
    display: flex;
  }
  // .right-input {
  //   border-right: unset;
  // }
  .amt-input {
    position: relative;
    padding: 16px 4px;
    border-right: 1px solid $accent-2-charcoal-10;
  }
  .width-15 {
    width: 15%;
  }
  .discout-input {
    width: 80px;
  }
  .content {
    font-weight: 400;
  }
  .add-product {
    .light-primary {
      padding: 10px 12px;
    }
  }
  .amount-block {
    width: 100%;
    .side-border {
      padding: 0;
    }
    .auto-complete-search-wrap {
      font-size: 12px;
      .wrapper:focus-within {
        z-index: 5 !important;
      }
      // .sc-eCYdqJ {
      //   .wrapper {
      //     z-index: 10 !important;
      //   }
      // }
      .search-bar-text {
        background: transparent;
        padding: 3px 0;
      }

      .sc-hKMtZM {
        .clear-icon {
          display: unset !important;
        }
      }
    }
  }
  .multi-input {
    .second-inp-field {
      margin-left: 8px;
      border-left: 1px solid $light-border;
      padding: 8px 0px 8px 8px;
    }
  }
  .formatted-result {
    white-space: initial;
    padding-right: 8px;
  }
  .search-icon {
    align-self: baseline;
  }
}
.add-button {
  margin: 12px 8px;
  img {
    margin: 0 8px 2px 0;
  }
}
.table-error {
  position: absolute;
  bottom: 0px;
}
.vat-field {
  width: 70px;
}

// .bar-code-input {
//   // visibility: hidden;
// }
.auto-complete-search-wrap{
  .wrapper:first-child svg {
    width: 25px;
    height: 25px;
    margin: 4px;
    margin-left: 12px;

  }
  
  // to target the input element
  .wrapper:first-child input {
    padding: 4px;
  }
  .wrapper>div {
    min-height: 37px;
  }
  .wrapper:first-child {
    border-radius: 0;
  }
}