@mixin input-style {
  padding: 8px 16px;
  width: 100%;
  border-radius: 4px;
  background: $semantics-white;
  border: 1px solid $accent-1-deep-indigo-25;
  font-size: 12px;
  font-weight: 400;
  color: $semantics-black;

  &:focus {
    outline: unset;
    border: 1px solid $sec-mint-100;
  }

  &:focus-within {
    box-shadow: none;
  }

  &:disabled {
    border: 1px solid $accent-1-deep-indigo-25;
    background: $accent-1-deep-indigo-5;
    color: $semantics-black;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: $accent-2-charcoal-50;
  }
}

@mixin btn-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  letter-spacing: 2px;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
  white-space: nowrap;
  background-color: $semantics-white;

  &:focus-visible {
    outline: unset;
    border: 3px double $semantics-white;
  }

  img {
    height: 16px;
    width: 16px;
  }
}

@mixin content-style {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: 0.25px;
  color: $contentColor;
}

@mixin label-style {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: $contentColor;
  letter-spacing: 0.25px;
  width: 100%;
  display: block;
  margin-bottom: 4px;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin d-flex {
  display: flex;
  align-items: center;
}

@mixin d-flex-start {
  display: flex;
  align-items: flex-start;
}

@mixin d-flex-end {
  display: flex;
  align-items: flex-end;
}

@mixin d-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin d-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin d-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin d-vertical-start {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@mixin d-vertical-end {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

@mixin display-v-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
