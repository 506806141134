.print_table {
  padding: 10px;

  .user-img-wrap {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .logo-section {
      border-radius: 50%;
      height: 35px;
      width: 35px;
    }
    .logo-title {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .text-center {
    text-align: center;
    display: flex;
    margin-top: 10px;
    gap: 5px;
    justify-content: center;
  }

  .block-title {
    font-weight: 500;
    .lighter {
      font-weight: 400;
      color: #000;
    }
  }
  .mg-left {
    margin-left: 10px;
  }
  .top-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
    font-weight: 800;
    padding-top: 40px;
    margin-bottom: 30px;
  }
}
.duration {
  display: "flex";
  gap: "2";
  justify-content: "space-between";
}
