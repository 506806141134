@import "../../styles/mixin";
@import "../../styles/variable";

.bank-detail-page {
  .width-100 {
    width: 100%;
  }
  .nepali-date-picker {
    position: absolute;
    top: 0;
  }
  .total-card {
    border: 1px solid $light-border;
    border-radius: 4px;
    width: 100%;
    .cash-border {
      border-right: 1px solid $light-border;
    }
    .header-wrap {
      border-bottom: 1px solid $light-border;
      .bank-header {
        padding: 16px;
        color: #2c2948;
      }
    }
    .side-spaces {
      padding: 12px 16px;
    }

    .bank-body-warp {
      .bank-detail-body {
        .bank-row {
          // padding: 12px 0 16px 0;
          .first-inp-block,
          .second-inp-block {
            width: 50%;
          }
          .input-group-append {
            z-index: 0;
          }
          .first-inp-block {
            margin-right: 16px;
          }
        }
        .second-row {
          padding: 0;
        }
      }
    }
  }
  .bank-details {
    margin-top: 12px;
  }
}

.check-box-area input[type="checkbox"]:checked {
  border: 1px solid #1db195;
  background-color: #1db195;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #1db195;
  border-radius: 4px;
  outline: none;
  margin-right: 12px;
  position: relative;
}
input[type="checkbox"]:checked {
  background: #1db195;
}
input[type="checkbox"]:checked:after {
  /* Heres your symbol replacement - this is a tick in Unicode. */
  content: "\2713";
  color: #fff;
  /* The following positions my tick in the center, 
     * but you could just overlay the entire box
     * with a full after element with a background if you want to */
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.check-label {
  margin-right: 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #818181;
  position: relative;
  bottom: 5px;
}
.checkbox {
  margin-right: 10px;
}
