@import "../../styles/mixin";
@import "../../styles/variable";

.new-product {
  // padding: 0 35px;
  .product-detail {
    margin-bottom: 16px;
  }
  .check-box-area {
    margin: 24px 0;
    @include d-flex;
    gap: 24px;
  }
  .line-border {
    border-bottom: 1px solid $accent-2-charcoal-10;
    @include d-flex-end;
    gap: 24px;
  }

  .relation-block {
    margin-top: 12px;
  }
  .calender-section {
    position: relative;
    .nepali-date-picker {
      bottom: -304px;
    }
  }
  .input-group-append {
    .btn-outline-secondary {
      border: none;
    }
    .btn-outline-secondary:hover {
      color: rgb(237, 18, 18);
    }
    button:hover {
      background-color: transparent;
    }
  }
  .mg-btm-15 {
    margin-bottom: 15px;
  }
}
