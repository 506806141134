@import "../../styles/mixin";
@import "../../styles/variable";

.recipt-slip-page {
  .searchable-name {
    padding-top: 12px;
  }

  .input-field {
    border: 1px solid $accent-1-deep-indigo-25;
  }
}
.check-box-area input[type="checkbox"]:checked {
  border: 1px solid #1db195;
  background-color: #1db195;
}
.check-box-wrapper {
  position: relative;

  .error {
    position: absolute;
    bottom: -20px;
  }
}
.check-box-section {
  display: flex;
  margin: 20px 0;
  input[type="checkbox"] {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #1db195;
    border-radius: 4px;
    outline: none;
    margin-right: 12px;
    position: relative;
  }
  input[type="checkbox"]:checked {
    background: #1db195;
  }
  input[type="checkbox"]:checked:after {
    /* Heres your symbol replacement - this is a tick in Unicode. */
    content: "\2713";
    color: #fff;
    /* The following positions my tick in the center, 
     * but you could just overlay the entire box
     * with a full after element with a background if you want to */
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .check-label {
    margin-right: 20px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #818181;
  }
  .checkbox {
    margin-right: 10px;
  }
}
