@import "../../styles/mixin";
@import "../../styles/variable";
.action-section {
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-icon {
    background: transparent;
    color: #000;
    border: none;
  }
  .dropdown-text {
    font-size: 12px;
    font-weight: 400;
  }
  .dropdown-eye {
    margin-right: 6px;
  }
}
.gap-2{
  p {
    margin-bottom: 0;
  }
}
.logo-section {
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background-color: $sec-mint-100;
  .dropdown-toggle::after {
    display: none;
  }
 
  img {
    border-radius: 50%;
    height: 35px;
    width: 35px;
    object-fit: cover;
  }
  .user-name {
    color: $semantics-white;
  }
}
